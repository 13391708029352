var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.socialmedia = (function ($) {
    "use strict";

    /**
     * A maximum of 23 characters are taken up by Public URLs and a tweet size is 280, less 1 for the space inserted after cursor
     * i.e. MAX_TWEET_LENGTH max starts at 256 since it excludes the referrer URL
     */
    var MAX_TWEET_LENGTH = 256;
    var MAX_TWEET_WITH_VIA_LENGTH = MAX_TWEET_LENGTH - " via @AllanGray".length;
    var MAX_TWEET_WITHOUT_VIA_LENGTH = MAX_TWEET_LENGTH - 3;  // -3 for surrounding quotes and whitespace between quote and URL

    function shortenTweet(suggestedText, includesVia) {
        var text = suggestedText.trim().replace(/(\.\.\.)/g, ''); // Remove starting and trailing ellipses in blockquotes 
        if (includesVia) {
            if (text.length > MAX_TWEET_WITH_VIA_LENGTH) {
                return text.substring(0, MAX_TWEET_WITH_VIA_LENGTH - 4).trim() + "...'"; // -4 for ellipses and quote
            }
        } else {
            if (text.length > MAX_TWEET_WITHOUT_VIA_LENGTH) {
                return text.substring(0, MAX_TWEET_LENGTH - 6).trim() + '...'; // -3 for ellipses and -3 for surrounding quotes and whitespace between quote and URL
            }
        }
        return text;
    }

    var activateSocialMedia = function (referralAreaParam, titleText, summaryText) {
        // Button transition
        $(".share-button").on("click", function (event) {
            $(this).parent().addClass("expanded");
        });

        // Open action in new page
        $(".social-media-links > a:not(.email), blockquote > a").attr("target", "_blank");

        // Common Page Information
        var pageTitle = document.title,
            url = document.URL,
            title = (titleText || $("h1").first().text()).replace(/ +/g, ' '),
            summary = (summaryText || $("p:first-child").text()).trim().replace(/ +/g, ' '),
            author = $(".avatar").text().trim(),
            image = $("img").first().length ? $("img").first()[0].src : '/images/ag-logo.png',
            referral = "Allan Gray " + (referralAreaParam || "Insights") + ": ",
            by = author ? "by " + author : "",
            lineBreak = encodeURIComponent("\n");

        // Bind page contents to widgets
        $(".email").attr("href",
            "mailto:" +
            "?subject=" + referral + title +
            "&body=Read this interesting article from Allan Gray: " + url);

        $(".twitter").attr("href", "https://twitter.com/intent/tweet?" +
            "&text=" + shortenTweet(referral + "'" + title + "' " + by, true) +
            "&via=AllanGray" +
            "&url=" + url +
            "&original_referer=" + url);


        $(".facebook").attr("href", "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url));

        $(".linkedin").attr("href", "http://www.linkedin.com/shareArticle?mini=true" +
            "&title=" + referral + title +
            "&source=" + encodeURIComponent(url) +
            "&url=" + encodeURIComponent(url) +
            "&summary=" + summary);

        // Block quote tweet
        $("blockquote").each(function () {
            var child = $(this).find("a.twitter");
            child.attr("href", "https://twitter.com/intent/tweet?" +
                "&text='" + shortenTweet($(this).text()) + "'" +
                "&url=" + url +
                "&original_referer=" + url)
                .attr("target", "_blank");
        });
    };

    return {
        activate: activateSocialMedia
    };

}(jQuery));
