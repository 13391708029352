var d3 = require('d3');
var accounting = require('accounting');
var moment = require('moment');

window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};
AG.d3.chart = AG.d3.chart || {};

AG.d3.chart.performance = (function (d3, accounting, moment) {
    "use strict";

    return function () {
        var margin = {
            top: 20,
            right: 25,
            bottom: 30,
            left: 34
        },
            width = 760,
            height = 480,
            yAxis = {
                ticks: 5,
                labelPadding: 10
            },
            xScale = d3.time.scale(),
            yScale = d3.scale.log(),

            boundaries = {
                min: undefined,
                max: undefined
            },
            yAxisVisible = true,
            endPointsVisible = false,

            line = d3.svg.line()
                .interpolate("linear")
                .x(function (d) {
                    return xScale(d.date);
                })
                .y(function (d) {
                    return yScale(d.value);
                });

        function yAxisInit(svg, data) {
            yScale
                .domain(
                    [
                        boundaries.min || d3.min(data, function (row) {
                            return d3.min(row, function (entry) {
                                return Math.floor(entry.value);
                            });
                        }),
                        boundaries.max || d3.max(data, function (row) {
                            return d3.max(row, function (entry) {
                                return Math.ceil(entry.value);
                            });
                        })
                    ])
                .range([height - margin.top - margin.bottom, 0]);

            if (yAxisVisible) {
                // Filter data for axis
                var tickHeightDiff = (height - margin.top - margin.bottom) / (yAxis.ticks - 1);
                var yTickValue = _.map(_.range(yAxis.ticks), function (d) {
                    return d * tickHeightDiff;
                });
                // Need to make sure the <amountInvested> point is always marked on the Y-Axis.
                var amountInvested = data[0][0].value;
                yTickValue.push(yScale(amountInvested)); // Force the <amountInvested> y-tick
                yTickValue = _.reject(yTickValue, function (item) {
                    var distance = Math.abs(item - yScale(amountInvested));
                    return (distance !== 0) && (distance < 10);
                })
                    .sort();

                // y-axis ticks
                var yTickEnter = svg.select(".y.axis")
                    .selectAll(".tick")
                    .data(yTickValue)
                    .enter()
                    .append("g")
                    .attr("class", function (d) {
                        return (yScale(amountInvested) === d) ? "tick invested" : "tick";
                    });

                yTickEnter.append("line");
                var labels = yTickEnter.append("text")
                    .attr("text-anchor", "end")
                    .text(function (d) {
                        return accounting.formatMoney(yScale.invert(d), "", 1, ",");
                    })
                    .attr("x", -(yAxis.labelPadding));

                // Recalculate left-padding based on y-axis values:
                margin.left = Math.ceil(d3.max(labels[0].map(function (label) {
                    return label.getComputedTextLength();
                }))) + yAxis.labelPadding;
            }
        }

        function xAxisInit(svg, data) {
            xScale.domain(d3.extent(data, function (d) {
                return d.date;
            }))
                .range([0, width - margin.left - margin.right]);

            function filterXAxisLabels(data) {
                var filteredData = data.filter(function (d, i) {
                    if (i % 12 === 0) {
                        return data[i];
                    }
                });

                if (filteredData.length > 12) {
                    filteredData = filteredData.filter(function (d, i) {
                        return i % 2 === 0;
                    })
                }
                var lastFiltered = filteredData[filteredData.length - 1].date;
                var lastEntry = moment(data[data.length - 1].date);
                var monthDiff = lastEntry.diff(lastFiltered, "months");

                // if diff less than x months, remove last xAxis tick
                // adjust based on number of ticks
                if (filteredData.length > 11) {
                    if (monthDiff < 9) {
                        filteredData.splice(-1, 1);
                    }
                } else {
                    if (monthDiff < 8) {
                        filteredData.splice(-1, 1);
                    }
                }


                filteredData.push(data[data.length - 1]);
                return filteredData;
            }

            var dataPoints = filterXAxisLabels(data);
            // x-axis ticks
            var xTickEnter = svg.select(".x.axis")
                .selectAll(".tick")
                .data(dataPoints)
                .enter()
                .append("g")
                .classed("tick", true);

            xTickEnter.append("line");
            xTickEnter.append("text")
                .attr("text-anchor", "middle")
                .text(function (d, i) {
                    if (i === dataPoints.length - 1 || i === 0) {
                        return moment(d.date).format("MMM YYYY");
                    }
                    return moment(d.date).format("YYYY");
                });
        }

        function chart(selection) {
            selection.each(function (data) {
                // Select the svg element, if it exists.
                var svg = d3.select(this)
                    .attr("width", width)
                    .attr("height", height);
                // Build the basic structure
                svg.append("g")
                    .classed("x axis", true);
                svg.append("g")
                    .classed("y axis", true);
                // Create the border rectangle
                svg.append("rect")
                    .classed("border", true);

                // Create the border (line version)
                svg.append("line")
                    .classed("border-left", true);
                svg.append("line")
                    .classed("border-bottom", true);


                // Create the path objects with their styling
                svg.append("g")
                    .classed("graph", true)
                    .selectAll(".line")
                    .data(data)
                    .enter()
                    .append("path")
                    .attr("class", function (line, i) {
                        return "line line" + i;
                    });

                if (endPointsVisible) {
                    svg.selectAll(".endpoint")
                        .data(data)
                        .enter()
                        .append("text")
                        .attr("class", function (d, i) {
                            return "endpoint label" + i;
                        })
                        .attr("text-anchor", "middle")
                        .text(function (d) {
                            return accounting.formatMoney(d[d.length - 1].value, "", 2, ",");
                        })
                        .data(data.map(function (d) {
                            return d[d.length - 1];
                        }));
                }

                // Build the Y-Axis
                yAxisInit(svg, data);
                // Build the X-Axis
                xAxisInit(svg, data[0]);
                // Update the positions and sizes
                chart.update(selection);
            });
        }

        chart.update = function (selection) {
            selection.each(function () {
                //if (AG.utilities.isMobile()) {
                //    height = 150;
                //}
                var svg = d3.select(this)
                    .attr("width", width)
                    .attr("height", height);

                // Update the x-scale.
                xScale.range([0, width - margin.left - margin.right]);

                // Update the x-axis
                var xAxis = svg.select(".x.axis");
                xAxis.selectAll("line")
                    .attr("y1", 0)
                    .attr("y2", -height + margin.top + margin.bottom)
                    .attr("x1", function (d) {
                        return xScale(d.date);
                    })
                    .attr("x2", function (d) {
                        return xScale(d.date);
                    });
                xAxis.selectAll("text")
                    .attr("y", 25)
                    .attr("x", function (d) {
                        return xScale(d.date);
                    });
                xAxis.attr("transform", "translate(" + margin.left + "," + (height - margin.bottom) + ")");

                // Update the y-axis
                var yAxis = svg.select(".y.axis");
                yAxis.selectAll("text")
                    .attr("y", function (d) {
                        return d + 5;
                    });

                yAxis.selectAll("line")
                    .attr("x1", 0)
                    .attr("x2", width - margin.left - margin.right)
                    .attr("y1", function (d) {
                        return d;
                    })
                    .attr("y2", function (d) {
                        return d;
                    });
                yAxis.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                /// UPDATE
                svg.select(".graph")
                    .selectAll(".line")
                    .attr("d", line);

                svg.selectAll(".endpoint")
                    .attr("dy", "-3px")
                    .attr("x", function (d) {
                        return xScale(d.date);
                    })
                    .attr("y", function (d) {
                        return yScale(d.value);
                    })
                    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

                // Resize the border rectangle
                svg.select("rect")
                    .attr("x", margin.left)
                    .attr("y", margin.top)
                    .attr("width", width - margin.left - margin.right)
                    .attr("height", height - margin.top - margin.bottom);

                // Resize the line version of the borders
                svg.select("line.border-left")
                    .attr("x1", margin.left)
                    .attr("y1", margin.top)
                    .attr("x2", margin.left)
                    .attr("y2", height - margin.bottom);

                svg.select("line.border-bottom")
                    .attr("x1", margin.left)
                    .attr("y1", height - margin.bottom)
                    .attr("x2", width - margin.right)
                    .attr("y2", height - margin.bottom);


                // Update the inner dimensions.
                svg.select(".graph").attr("transform", "translate(" + margin.left + "," + margin.top + ")");
            });
        };

        chart.tweenMe = function (selection) {
            selection.each(function (data) {
                var svg = d3.select(this);
                var lines = svg.select(".graph")
                    .selectAll(".line")
                    .data(data);
                var text = svg.selectAll(".endpoint")
                    .data(data.map(function (d) {
                        return d[d.length - 1];
                    }));
                var numDataLines = lines.size();

                /// ENTER
                lines.enter()
                    .append("path")
                    .attr("class", function (line, i) {
                        return "line prevline" + (i % numDataLines);
                    })
                    .attr("opacity", 0)
                    .attr("d", line);

                lines
                    .transition()
                    .delay(200)
                    .duration(2000)
                    .attr("opacity", 1)
                    .attr("d", function (d) {
                        return line(d);
                    });

                text
                    .transition()
                    .duration(2000)
                    .attr("y", function (d) {
                        return yScale(d.value);
                    });

                /// EXIT
                lines
                    .exit()
                    .transition()
                    .duration(3000)
                    .attr("opacity", 0)
                    .remove();
            });
        };

        /*
         * Feature: grow the lines from the origin.
         * Use by calling: `lines.call(animateLines)`
         */
        function animateLines(lines) {
            lines.attr("d", line)
                .attr("stroke-dasharray", function () {
                    return this.getTotalLength() + " " + this.getTotalLength();
                })
                .attr("stroke-dashoffset", function () {
                    return this.getTotalLength();
                })
                .transition()
                .duration(2000)
                .ease("linear")
                .attr("stroke-dashoffset", function () {
                    return 0;
                });
        }

        chart.margin = function (_) {
            if (!arguments.length) {
                return margin;
            }
            margin = _;
            return chart;
        };

        chart.width = function (_) {
            if (!arguments.length) {
                return width;
            }
            // Minimum width is 768
            if (_ > 768) {
                width = _;
            }
            return chart;
        };

        chart.height = function (_) {
            if (!arguments.length) {
                return height;
            }
            height = _;
            return chart;
        };
        chart.yAxisVisible = function (_) {
            if (!arguments.length) {
                return yAxisVisible;
            }
            yAxisVisible = _;
            return chart;
        };

        chart.endPointsVisible = function (_) {
            if (!arguments.length) {
                return endPointsVisible;
            }
            endPointsVisible = _;
            return chart;
        };

        chart.resize = function () {
            return chart;
        };


        chart.boundaries = function (_) {
            if (!arguments.length) {
                return boundaries;
            }
            boundaries = _;
            return chart;
        };

        return chart;
    };

})(d3, accounting, moment);
