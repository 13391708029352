var jQuery = require('jquery');
var accounting = require('accounting');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.tables = (function($, accounting) {
    "use strict";
    accounting.settings = {
        currency: {
            symbol: "R", // default currency symbol is '$'
            format: "%s%v", // controls output: %s = symbol, %v = value/number (can be object: see below)
            decimal: ".", // decimal point separator
            thousand: " ", // thousands separator
            precision: 2 // decimal places
        },
        number: {
            precision: 2, // default precision on numbers is 0
            thousand: " ",
            decimal: "."
        }
    };

    var currencyPattern = new RegExp("[R$£€]");

    var reformat = function() {
        $(".js-currency").each(function() {
            var $element = $(this);
            if($element.hasClass("js-one-decimal")) {
                $element.html(getFormattedCurrency($element.html(), 1));
            }
            else if($element.hasClass("js-two-decimal")) {
                $element.html(getFormattedCurrency($element.html(), 1));
            }
        });

        $(".js-number").each(function() {
            var $element = $(this),
                number = $element.html();
            if($element.hasClass("js-one-decimal")) {
                $element.html(accounting.formatNumber(number, 1));
            }
            else if($element.hasClass("js-two-decimal")) {
                $element.html(accounting.formatNumber(number, 2));
            }
        });
    };

    var getFormattedCurrency = function(datum, decimals) {
        var res = currencyPattern.exec(datum),
            symbol = res ? res[0] : "R";
        return accounting.formatMoney(datum, symbol, decimals);
    };

    //call automatically on load.
    $(document).ready(function() {
        reformat();
    });

    return {
        reformat: reformat
    };
}(jQuery, accounting));
