var $ = require('jquery');

// Extending Bootstrap's Collapse
$(document).on("show.bs.collapse", function(event) {
  $(event.target).prev(".panel-heading").addClass("collapse-open");
});

$(document).on("hide.bs.collapse", function(event) {
  $(event.target).prev(".panel-heading").removeClass("collapse-open");
});
