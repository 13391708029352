var d3 = require('d3');
var accounting = require('accounting');


window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};

AG.d3.components = (function (d3, accounting) {
    "use strict";
    var legend = function () {
        // Default width and height of the colour blocks
        var layout = AG.d3.constants.LEGEND_LAYOUT.VERTICAL,
            position = 0,
            platform = AG.d3.constants.PLATFORM.DESKTOP,
            heading = false;

        var component = function (parentSelection, data) {
            parentSelection.each(function () {
                var container = d3.select(this)
                    .append("div");
                switch (platform) {
                    case AG.d3.constants.PLATFORM.DESKTOP:
                        container.classed("legend-container hidden-xs", true);
                        break;
                    case AG.d3.constants.PLATFORM.MOBILE:
                        container.classed("legend-container visible-xs", true);
                        break;
                    default:
                        container.classed("legend-container", true);
                        break;
                }

                var legend = container
                    .append("div")
                    .classed("legend", true);

                var legendData = data;
                if (heading) {
                    var header = legend
                        .append("div")
                        .classed("legend__header legend__header--custom legend__item--vertical", true);
                    header.append("div")
                        .classed("legend__header__cell legend__identifier--vertical", true);
                    header.append("div")
                        .classed("legend__header__cell legend__name--vertical", true)
                        .text(legendData[0].name);
                    if (data[0].values) {
                        header.append("div")
                            .classed("legend__header__cell legend__value--vertical", true)
                            .text(legendData[0].values[0]);
                        header.append("div")
                            .classed("legend__header__cell legend__value--vertical", true)
                            .text(legendData[0].values[1]);
                    } else {
                        header.append("div")
                            .classed("legend__header__cell legend__value--vertical", true)
                            .text(legendData[0].value);
                    }

                    legendData = legendData.slice(1);
                }

                var legendItem = legend
                    .selectAll(".legend__item")
                    .data(legendData)
                    .enter()
                    .append("div")
                    .classed("legend__item", true);

                legendItem
                    .append("div")
                    .classed("legend__identifier", true)
                    .append("div")
                    .attr("class", function (d, i) {
                        switch (i) {
                            case 1:
                                return "legend__block legend__block--secondary";
                            case 2:
                                return "legend__block legend__block--pattern";
                        }
                        return "legend__block legend__block--primary";
                    });

                legendItem
                    .append("div")
                    .classed("legend__name", true)
                    .html(function (d) {
                        return d.name;
                    });

                if (legendData[0].value) {
                    legendItem
                        .append("div")
                        .attr("class", function (d, i) {
                            return "legend__value legend__value--fund" + i;
                        })
                        .text(function (d) {
                            return d.value;
                        });
                } else if (legendData[0].values) {
                    legendItem.selectAll("legend__value")
                        .data(function (d, i) {
                            return d.values.map(function (v, x) {
                                return {
                                    fund: i,
                                    index: x,
                                    value: v
                                }
                            });
                        })
                        .enter()
                        .append("div")
                        .attr("class", function (d, i, j) {
                            return "legend__value legend__value--fund" + d.fund;
                        })
                        .text(function (d) {
                            return d.value;
                        });
                }

                var layoutClass = (layout === AG.d3.constants.LEGEND_LAYOUT.VERTICAL ? "--vertical" : "--horizontal");
                if (position === AG.d3.constants.POSITION.TOP_LEFT) {
                    container.classed("legend-container--topleft", true);
                }
                container.classed("legend-container" + layoutClass, true);
                container.select(".legend").classed("legend" + layoutClass, true);
                container.selectAll(".legend__item").classed("legend__item" + layoutClass, true);
                container.selectAll(".legend__identifier").classed("legend__identifier" + layoutClass, true);
                container.selectAll(".legend__name").classed("legend__name" + layoutClass, true);
                if (legendData[0].value || legendData[0].values) {
                    container.selectAll(".legend__value").classed("legend__value" + layoutClass, true);
                }
            });
        };

        component.update = function (selection, data) {
            var legendData = heading ? data.slice(1) : data;
            if (data[0].value) {
                selection
                    .select(".legend")
                    .selectAll(".legend__value")
                    .transition()
                    .duration(2000)
                    .ease("linear")
                    .tween("text", function (d, x) {
                        var value = +(legendData[x].value);
                        var context = this.textContent.replace(/ /g, "");
                        var i = d3.interpolateNumber(context, value);
                        return function (t) {
                            this.textContent = accounting.formatNumber(i(t), {
                                precision: 1
                            });
                        };
                    });
            } else if (data[0].values) {
                selection
                    .select(".legend")
                    .selectAll(".legend__item")
                    .selectAll(".legend__value")
                    .transition()
                    .duration(2000)
                    .ease("linear")
                    .tween("text", function (d, x, j) {
                        var value = +(legendData[d.fund].values[d.index]);
                        var context = this.textContent.replace(/ /g, "");
                        var i = d3.interpolateNumber(context, value);
                        return function (t) {
                            this.textContent = accounting.formatNumber(i(t), {
                                precision: 1
                            });
                        };
                    });
            }
        };

        component.layout = function (_) {
            if (!arguments.length) {
                return layout;
            }
            layout = _;
            return component;
        };

        component.position = function (_) {
            if (!arguments.length) {
                return position;
            }
            position = _;
            return component;
        };

        component.platform = function (_) {
            if (!arguments.length) {
                return platform;
            }
            platform = _;
            return component;
        };

        component.heading = function (_) {
            if (!arguments.length) {
                return heading;
            }
            heading = _;
            return component;
        };

        return component;
    };

    return {
        legend: legend
    };
}(d3, accounting));
