var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.quarterselector = (function($) {
    "use strict";

    var activateQuarterSelector = function() {

        $(".quarter-selector-button").on("click", function() {
            if (AG.utilities.isMobile()) {
                if(!$(".quarter-selector-container").hasClass("open")) {
                    AG.utilities.scrollToElement($(".quarter-selector-container").prev());
                }
            }
            $(".quarter-selector-container").toggleClass("open");
            $(".year-container").toggleClass("expanded");
            $(".year-container").find(".expanded").removeClass("expanded");
            $(".year-container").find(".selected").removeClass("selected");
        });

        $("li.year").on("click", function() {
            $(this).siblings(".selected").children().removeClass("expanded");
            $(this).siblings(".selected").removeClass("selected");
            $(this).toggleClass("selected");
            $(this).children("ul").toggleClass("expanded");
        });

        // Menu hiding
        $(document).click(function(event) {
            if (!$(event.target).closest('.quarter-selector-container').length) {
                if ($('.quarter-selector-container').hasClass("open")) {
                    $('.quarter-selector-container').removeClass("open");
                    $(".quarter-selector-container .expanded").removeClass("expanded");
                }
            }
        });

    };

    return {
        activate: activateQuarterSelector
    };

}(jQuery));
