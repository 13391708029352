window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};

AG.d3.constants = (function () {
    "use strict";

    var PLATFORM = {
        DESKTOP: 1,
        MOBILE: 2,
        ALL: 3
    };

    var POSITION = {
        TOP_LEFT: 1
    };

    var LEGEND_LAYOUT = {
        VERTICAL: 1,
        HORIZONTAL: 2
    };

    var BAR_LAYOUT = {
        STACKED: 1,
        GROUPED: 2
    };

    var LABEL_LOCATION = {
        INSIDE_RIGHT: 1,
        OUTSIDE_RIGHT: 2,
        ABOVE_BAR: 3,
        OFF: 4
    };

    return {
        POSITION: POSITION,
        PLATFORM: PLATFORM,
        LEGEND_LAYOUT: LEGEND_LAYOUT,
        BAR_LAYOUT: BAR_LAYOUT,
        LABEL_LOCATION: LABEL_LOCATION
    };
}());
