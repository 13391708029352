var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.globalnotice = (function($) {
    "use strict";

    
    var activateGlobalNotice = function() {

      var $globalNotice       = $(".global-notice");
      var perma               = $globalNotice.data('noticeperma');  
      var expirationInSeconds = $globalNotice.data('noticeexpire');

      // 30 days default unless specified via data-noticeexpire
      var expirationDuration = expirationInSeconds ? 1000 * parseInt(expirationInSeconds) : 1000 * 60 * 60 * 24 * 30;

      if($globalNotice.length){
        
        // permanent? always show on page load
        if(perma){
          $globalNotice.addClass('is-active');
        
        } else {  

          // if notice previously existed
          if ( localStorage.getItem("globalnotice_" + $globalNotice.data('noticeid')) ) {

            var currentTime = new Date().getTime();
            var acceptedTime = parseInt( localStorage.getItem("globalnotice_" + $globalNotice.data('noticeid')) );
            
            if(isNaN(acceptedTime) || acceptedTime == null){ 
              // if timestamp not in expected format, show notice
              $globalNotice.addClass('is-active');         

            } else {
              
              if( (currentTime - acceptedTime) < expirationDuration ){
                // within time limit, remove
                $globalNotice.remove();

              } else { 
                // over time limit, show again
                $globalNotice.addClass('is-active');
              } 

            }            

          // else new notice
          } else {
            $globalNotice.addClass('is-active');            
          }

        }
        
        // on dismiss button click, store choice and remove
        $globalNotice.find('.global-notice__close').on('click', function(){

          if(!perma){
            // set timestamp when closed
            localStorage.setItem("globalnotice_" + $globalNotice.data('noticeid'), new Date().getTime());
          }

          $globalNotice.remove();
        });

      }

        
    };

    return {
        activateGlobalNotice: activateGlobalNotice
    };

}(jQuery));
