var jQuery = require('jquery');
var _ = require('underscore');

window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};

AG.d3.behaviours = (function($, _) {
    "use strict";

    var autoScale = function(selection, chart, axis, aspectRatioLock) {
        axis = axis || "x";
        aspectRatioLock = aspectRatioLock || false;
        var aspectRatio;
        if (selection.node() === null) {
            return;
        }
        var svg = selection;
        if (selection.node().nodeName !== "svg") {
            svg = selection.select("svg");
        }
        var $el = $(svg.node().parentNode);

        var _scaleChart = function() {

            var elWidth = $el.width();
            var elHeight = $el.height();

            // Shortcut the scaling if the values are pointless
            if (elWidth === 0 || elHeight === 0) {
                return;
            }
            // Don't resize if the width / height haven't changed
            if (axis === "x" && elWidth === chart.width()) {
                return;
            }
            if (axis !== "x" && elHeight === chart.height()) {
                return;
            }

            if (axis === "x") {
                chart.width(elWidth);
            } else {
                chart.height(elHeight);
            }

            if (aspectRatioLock) {
                aspectRatio = chart.width() / chart.height();
                if (axis === "x") {
                    chart.height(elWidth / aspectRatio);
                } else {
                    chart.width(elHeight * aspectRatio);
                }
            }

            //TODO: need to undo this after graphs have been refactored to implement .update()
            if (chart.update) {
                chart.update(selection);
            } else {
                chart(selection);
            }
        };

        var scaleChart = _.debounce(_scaleChart, 25);
        $(window).resize(scaleChart);
        _scaleChart();

        return selection;
    };

    var reposition = function(selection, component) {
        var _repositionComponent = function() {
            component(selection);
        };

        var repositionComponent = _.debounce(_repositionComponent, 50);
        $(window).resize(repositionComponent);
        _repositionComponent();

        return selection;
    };

    return {
        autoScale: autoScale,
        reposition: reposition
    };
})(jQuery, _);
