var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.videoCustom = (function ($) {
    "use strict";

    var youTubePlayer = [];
    var retryAttempts = [];

    /**
     * @param {Object} params The parameters described below
     * videoStarted callback function to run on initial play event of the video
     */
    var initCustomVideos = function (params) {

        // only include youtube iframe api script if not already included (by other components)
        if ($('.custom-video').length && $('script[src="https://www.youtube.com/iframe_api"]').length < 1) {
            var tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }


        $(document).on('click', '.custom-video__watch', function (e) {
            e.preventDefault();

            // prevent attaching multiple click events
            if ($(this).data('init')) return;
            $(this).data('init', true);

            handleClick($(this).closest('.custom-video'), params);
        });

    };

    var handleClick = function ($el, params) {

        var videoId = $el.data('video-id');
        var videoTitle = $el.find('.custom-video__watch-title').text();
        var videoPlayerId = 'custom-video-' + videoId;
        $el.find('.custom-video__iframe').attr('id', videoPlayerId);

        retryAttempts[videoId] = 0;
        loadVideo($el, videoId, videoPlayerId, videoTitle, params);
    }

    var loadVideo = function ($el, videoId, videoPlayerId, videoTitle, params) {
        $el.addClass('is-loading');
        var videoStartedEvent = false;
        try {
            retryAttempts[videoId] += 1;
            youTubePlayer[videoId] = new YT.Player(videoPlayerId, {
                videoId: videoId,
                width: '100%',
                height: '100%',
                playerVars: {
                    autoplay: 0,
                    controls: 1,
                    enablejsapi: 1,
                    fs: 1,
                    showinfo: 0,
                    rel: 0
                },
                events: {
                    // 'onStateChange': createYTEvent(videoPlayerId),
                    'onReady': function (event) {
                        $el.removeClass('is-loading').addClass('has-loaded');
                        setTimeout(function () {
                            $el.find('.custom-video__poster').fadeOut(300);
                        }, 150);

                        youTubePlayer[videoId].setPlaybackQuality("highres");
                        youTubePlayer[videoId].playVideo();
                    },
                    'onStateChange': function (event) {
                        if (event.data === YT.PlayerState.PLAYING && !videoStartedEvent) {
                            videoStartedEvent = true;
                            if (params.videoStarted) params.videoStarted(videoTitle, videoId);
                        }
                    }
                }
            });
        } catch (err) {
            console.log("Failed to load video. Error: ", err);

            // retry once every second, 10 times
            if (retryAttempts[videoId] <= 10) {
                setTimeout(function () {
                    console.log("retrying...");
                    loadVideo($el, videoId, videoPlayerId);
                }, 1000);
                // else reset and present play button again
            } else {
                $el.removeClass('is-loading');
            }
        }
    }


    return {
        initCustomVideos: initCustomVideos
    };

}(jQuery));
