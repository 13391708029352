var $ = require('jquery');

/* ---- ==== Info Notes ==== ---- */
var closeAllInfoNotes = function () {
    $(".info-note").removeClass("active");
};


var activateInfoNote = function () {
    var $infoNote = $(this).closest(".info-note"),
        $infoNoteContent = $infoNote.find("> .note-content");

    // reset the info notes
    $(".info-note").removeClass("active");
    $infoNote.toggleClass("active");

    // detect bounds and position
    var wellWidth = $infoNote.closest('.well').width(),
        wellOffset = $infoNote.closest('.well').offset().left;
    noteOffset = $infoNote.offset().left - wellOffset - 30;

    if (noteOffset / wellWidth > 0.5) {
        $infoNote.addClass('info-note--left');
    } else {
        $infoNote.removeClass('info-note--left');
    }

    // mobile: expand note content to width of window
    if (AG.utilities.isMobile()) {
        var $mobileInfoNote = $(this),
            mobileinfonoteWidth = $mobileInfoNote.closest(".well, .container").width(),
            mobileftinfoOffset = $mobileInfoNote.offset().left;

        $infoNoteContent.css({
            'width': mobileinfonoteWidth - 10,
            'height': 'auto',
            'position': 'absolute',
            'left': -mobileftinfoOffset + 25
        });

    } else {
        $infoNoteContent.removeAttr('style');
    }

};


// activate notes on click and/or hover
$(document).on("click mouseenter", ".info-note .note", activateInfoNote);


$(document).on("click", ".info-note .note-close", function () {
    $(this).closest('.info-note').removeClass("active");
});

// close all notes when document is clicked
$(document).on("click", function (event) {
    if (!$(event.target).closest(".info-note").length) {
        closeAllInfoNotes();
    }
});

// close tooltips when notes are open
$("body").on("click", ".extra-info-title", function () {
    if ($(".info-note").hasClass("active")) {
        closeAllInfoNotes();
    }
});


/* ---- ===== ==== ----*/