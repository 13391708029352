var jQuery = require('jquery');
var _ = require('underscore');

window.AG = window.AG || {};

AG.utilities = (function ($, _) {
    "use strict";

    var isMobile = function () {
        return (window.matchMedia("(max-width: " + AG.constants.MOBILE_MAX_WIDTH + "px)").matches);
    };

    var isTouch = function () {
        return (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
    };

    var isOverflown = function ($e) {
        var e = $e[0];
        return e.scrollHeight > e.clientHeight || e.scrollWidth > e.clientWidth;
    }

    var isSmallerThan = function (screenSize) {
        if (typeof screenSize !== "number") {
            throw "Expected the argument to be a number. Argument was " + (typeof screenSize);
        }
        return (window.matchMedia("(max-width: " + screenSize + "px)").matches);
    };

    var hasStorageAvailable = function (type) {
        try {
            var storage = window[type],
                x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch (e) {
            return false;
        }
    };

    // Function from David Walsh: http://davidwalsh.name/css-animation-callback
    var whichTransitionEvent = function () {
        var t,
            el = document.createElement("fakeelement");

        var transitions = {
            "transition": "transitionend",
            "OTransition": "oTransitionEnd",
            "MozTransition": "transitionend",
            "WebkitTransition": "webkitTransitionEnd"
        };

        for (t in transitions) {
            if (el.style[t] !== undefined) {
                return transitions[t];
            }
        }
    };

    var scrollToElement = function (element, offset, speed, callback) {
        if ($(element).length) {
            // default offset: 0
            var offset = typeof offset !== 'undefined' ? offset : 0;
            var speed = typeof speed !== 'undefined' ? speed : 450;


            $("html, body").animate({
                scrollTop: $(element).offset().top - offset
            }, speed, function () {
                if (callback) callback();
            });
        }
    };

    var getQueryVariable = function (variable) {
        var query = window.location.search.substring(1);
        var vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    };


    var textTrimmer = function () {
        var targetSelector,
            parentSelector,
            targetEls,
            parentEl;

        var utility = function () { };

        utility.init = function (target, parent) {
            targetSelector = target;
            parentSelector = parent;
            targetEls = document.querySelectorAll(target);
            parentEl = document.querySelector(parent);

            /// hide text whilst we wait for font to load
            _.each(targetEls, function (targetEl) {
                $(targetEl).css("visibility", "hidden");
            });

            // init only once webfonts have loaded (or failed to load)
            Promise.all([AG.fontMerriweather.load(), AG.fontRobotoSlab.load(), AG.fontRoboto.load()]).then(function () {
                utility.shortenText();
                var _shortenText = _.debounce(utility.shortenText, 50);
                window.addEventListener('resize', _shortenText);
            });

            return utility;
        };

        utility.shortenText = function () {
            targetEls = document.querySelectorAll(targetSelector);
            $(targetSelector).html(""); //clear target HTML so as not to give false overflow impression.

            _.each(targetEls, function (targetEl) {
                $(targetEl)
                    .closest(parentSelector)
                    .css("overflow", "auto"); //this needs to be set to get the correct scrollHeight


                var titleText = targetEl.getAttribute('data-title');
                if (isMobile()) {
                    $(targetEl).html(titleText);
                } else {
                    try {
                        var arrayOfWords = titleText.split(" ");
                        var trimmedWords = [];

                        for (var i = 0; i < arrayOfWords.length; i++) {
                            trimmedWords.push(arrayOfWords[i]);
                            $(targetEl).html(trimmedWords.join(" "));

                            if ($(targetEl).closest(parentSelector).outerHeight() < $(targetEl).closest(parentSelector)[0].scrollHeight) {
                                trimmedWords.pop();
                                trimmedWords.pop();

                                // RegExp removes any punctuation left at the end of the last word.
                                trimmedWords.push(trimmedWords.pop().replace(/[^\w\s]$/, "") + "...");

                                $(targetEl).html(trimmedWords.join(" "));
                                if (trimmedWords.length) {
                                    $(targetEl).html($(targetEl).html());
                                }
                                break;
                            }
                        }
                    } catch (e) {
                        $(targetEl).html(''); // clear on fail (no trimmed words left to push)
                        console.log("The title you're trying to truncate is equal to:", titleText, "Check that the data-title attribute is set.");
                    }
                }
                $(targetEl)
                    .css("visibility", "visible") // unhide text
                    .closest(parentSelector).css("overflow", "visible"); //Remove overflow again 
            });

            return utility;
        };

        return utility;
    };

    // setTimeout replacement
    var rtimeOut = function (callback, delay) {
        var dateNow = Date.now,
            requestAnimation = window.requestAnimationFrame,
            start = dateNow(),
            stop,
            timeoutFunc = function () {
                dateNow() - start < delay ? stop || requestAnimation(timeoutFunc) : callback()
            };
        requestAnimation(timeoutFunc);
        return {
            clear: function () { stop = 1 }
        }
    };

    // setInterval replacement
    var rInterval = function (callback, delay) {
        var dateNow = Date.now,
            requestAnimation = window.requestAnimationFrame,
            start = dateNow(),
            stop,
            intervalFunc = function () {
                dateNow() - start < delay || (start += delay, callback());
                stop || requestAnimation(intervalFunc)
            }
        requestAnimation(intervalFunc);
        return {
            clear: function () { stop = 1 }
        }
    };


    var isElementInViewport = function (el) {

        // Special bonus for those using jQuery
        if (typeof jQuery === "function" && el instanceof jQuery) {
            el = el[0];
        }

        var rect = el.getBoundingClientRect();

        return rect.bottom > 0 &&
            rect.right > 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight);
    }

    var cookies = {
        // this gets a cookie and returns the cookies value, if no cookies it returns blank ""
        get: function (c_name) {
            if (document.cookie.length > 0) {
                var c_start = document.cookie.indexOf(c_name + "=");
                if (c_start !== -1) {
                    c_start = c_start + c_name.length + 1;
                    var c_end = document.cookie.indexOf(";", c_start);
                    if (c_end === -1) {
                        c_end = document.cookie.length;
                    }
                    return decodeURI(document.cookie.substring(c_start, c_end));
                }
            }
            return "";
        },

        // this sets a cookie with your given ("cookie name", "cookie value", "good for x days")
        set: function (c_name, value, expiredays) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + expiredays);
            document.cookie = c_name + "=" + encodeURI(value) + "; path=/" + ((expiredays === null) ? "" : "; expires=" + exdate.toUTCString());
        },

        // this checks to see if a cookie exists, then returns true or false
        check: function (c_name) {
            c_name = cookies.get(c_name);
            if (c_name !== null && c_name !== "") {
                return true;
            } else {
                return false;
            }
        }
    }

    return {
        isMobile: isMobile,
        isTouch: isTouch,
        isOverflown: isOverflown,
        isSmallerThan: isSmallerThan,
        hasStorageAvailable: hasStorageAvailable,
        whichTransitionEvent: whichTransitionEvent,
        scrollToElement: scrollToElement,
        textTrimmer: textTrimmer,
        getQueryVariable: getQueryVariable,
        rtimeOut: rtimeOut,
        rInterval: rInterval,
        isElementInViewport: isElementInViewport,
        cookies: cookies
    };
}(jQuery, _));
