var d3 = require('d3');
var accounting = require('accounting');

window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};

AG.d3 = (function (d3, accounting) {
    "use strict";

    var verticalSplitBarGraph = function () {
        var width,
            height,
            barWidth;

        var _maxY;

        var chart = function (selection) {
            selection.each(function (rawData) {

                var offshore = rawData.reduce(function (prev, curr) {
                    return prev + (+(curr["offshore"] || curr["Offshore"]));
                }, 0),
                    local = rawData.reduce(function (prev, curr) {
                        return prev + (+(curr["local"] || curr["local"]));
                    }, 0);

                var data = [{
                    label: "Offshore",
                    y: 0,
                    value: offshore
                }, {
                    label: "Local",
                    y: offshore,
                    value: local
                }];

                _maxY = d3.max(data, function (d) {
                    return d.y + d.value;
                });

                var svg = d3.select(this);

                svg.append("rect")
                    .classed("background", true);

                svg.append("line")
                    .attr("class", "divider");

                svg.selectAll(".data")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", function (d) {
                        return AG.d3.utilities.classify(d.label);
                    })
                    .classed("data", true);

                var dataLabels = svg.selectAll(".data-label")
                    .data(data)
                    .enter()
                    .append("text")
                    .attr("alignment-baseline", "middle")
                    .classed("data-label", true);

                dataLabels
                    .append("tspan")
                    .attr("class", "value")
                    .text(function (d) {
                        return accounting.formatNumber(d.value, 1, "") + "% ";
                    });

                dataLabels
                    .append("tspan")
                    .text(function (d) {
                        return d.label;
                    });
            });

            chart.update(selection);
        };

        chart.update = function (selection) {
            if (AG.utilities.isMobile()) {
                return;
            }
            var padL = $(selection.node().parentNode).css('padding-left').replace("px", "");
            var padR = $(selection.node().parentNode).css('padding-right').replace("px", "");
            width = selection.node().parentNode.getBoundingClientRect().width - padL - padR;
            if (!(width && height)) {
                return;
            }

            selection.each(function () {
                var svg = d3.select(this),
                    bars = svg.selectAll(".data"),
                    dataLabel = svg.selectAll(".data-label"),
                    dividingLine = svg.select(".divider"),
                    maxLabelWidth = d3.max(dataLabel[0].map(function (label) {
                        return label.getComputedTextLength();
                    }));

                var y = d3.scale.linear()
                    .range([0, height])
                    .domain([0, _maxY]);

                // fix: negative width value causing errors
                width = (width < 1) ? 1 : width;

                selection
                    .attr("height", height)
                    .attr("width", width);

                selection
                    .select(".background")
                    .attr("height", height - 1)
                    .attr("width", width - 1);

                bars.attr("y", function (d) {
                    return y(d.y);
                })
                    .attr("height", function (d) {
                        return y(d.value);
                    })
                    .attr("width", barWidth);

                var value = bars.data()[0].value;
                dividingLine
                    .attr("x1", 0)
                    .attr("x2", barWidth)
                    .attr("y1", y(value))
                    .attr("y2", y(value))
                    .style({
                        "stroke-width": "2",
                        "stroke": "#f9f9f9"
                    });

                dataLabel
                    .attr("y", function (d) {
                        return y(d.y) + y(d.value) / 2;
                    })
                    .attr("x", (chart.width() / 2) - maxLabelWidth / 2);
            });
        };

        chart.barWidth = function (_) {
            if (!arguments.length) {
                return barWidth;
            }
            barWidth = _;
            return chart;
        };

        chart.height = function (_) {
            if (!arguments.length) {
                return height;
            }
            height = _;
            return chart;
        };

        chart.width = function (_) {
            if (!arguments.length) {
                return width;
            }
            width = _;
            return chart;
        };

        chart.width(width || 300);
        chart.height(height || 480);
        chart.barWidth(barWidth || 30);

        return chart;
    };

    var horizontalSplitBarGraph = function () {
        var width,
            barHeight;
        var _domainMax = 100;
        var chart = function (selection) {

            selection.each(function (rawData, i) {

                var offshore = rawData.reduce(function (prev, curr) {
                    return prev + (+(curr["offshore"] || curr["Offshore"]));
                }, 0),
                    local = rawData.reduce(function (prev, curr) {
                        return prev + (+(curr["local"] || curr["local"]));
                    }, 0);

                var data = [{
                    label: "Local",
                    y: 0,
                    value: local

                }, {
                    label: "Offshore",
                    y: local,
                    value: offshore
                }];

                var graph = selection.append("g")
                    .classed("horizontal bargraph", true);

                var bars = graph
                    .append("g")
                    .classed("bars", true);

                graph
                    .append("line")
                    .attr("class", "divider");

                bars.selectAll(".data")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", function (d) {
                        return AG.d3.utilities.classify(d.label);
                    })
                    .classed("data", true);

                var labels = graph
                    .append("g")
                    .classed("labels", true);

                var dataLabels = labels.selectAll(".data-label")
                    .data(data)
                    .enter()
                    .append("text")
                    .classed("data-label", true);

                dataLabels
                    .append("tspan")
                    .attr("class", "value")
                    .text(function (d) {
                        return accounting.formatNumber(d.value, 2, "") + "%";
                    });

                dataLabels
                    .append("tspan")
                    .attr("dy", "1.2em")
                    .attr("x", "0")
                    .text(function (d) {
                        return d.label;
                    });

            });

            chart.update(selection);
        };

        chart.update = function (selection) {
            chart.width(selection.node().parentNode.getBoundingClientRect().width);

            selection.each(function () {
                var graph = selection.select(".bargraph");
                var bars = graph.select(".bars");
                var dataLabel = graph.selectAll(".data-label");
                var dividingLine = graph.select(".divider");

                var dividerPosition;
                var barWidthScale = d3.scale.linear()
                    .range([0, width])
                    .domain([0, _domainMax]);

                bars.selectAll(".data")
                    .attr("x", function (d) {
                        dividerPosition = barWidthScale(d.y);
                        return barWidthScale(d.y);
                    })
                    .attr("width", function (d) {
                        return barWidthScale(d.value);
                    })
                    .attr("height", barHeight);

                dividingLine
                    .attr("x1", dividerPosition)
                    .attr("x2", dividerPosition)
                    .attr("y1", 0)
                    .attr("y2", barHeight);

                dataLabel.attr("x", 0)
                    .attr("y", "4em")
                    .attr("transform", function (d) {
                        return "translate(" + (barWidthScale(d.y) + (barWidthScale(d.value) / 2)) + ", 0)";
                    });
            });

            var barsContainer = selection.select(".horizontal.bargraph");
            selection.attr("height", barsContainer.node().getBBox().height);
        };

        chart.barHeight = function (bH) {
            if (!arguments.length) {
                return barHeight;
            }
            barHeight = bH;
            return chart;
        };

        chart.width = function (w) {
            if (!arguments.length) {
                return width;
            }
            width = w;
            return chart;
        };

        chart.width(width || 300);
        chart.barHeight(barHeight || 30);

        return chart;
    };

    var spacedBarGraph = function () {
        var width = 260,
            globalMin,
            globalMax,
            svg;

        function chart(selection) {
            selection.each(function (data) {
                if (data === undefined) {
                    console.log("!!! Failed to render Spaced Bar Graph. Data is undefined");
                    return;
                }

                svg = d3.select(this)
                    .classed("spaced-bar-graph", true);

                svg.selectAll("*").remove();
                var pattern = AG.d3.utilities.appendStripePattern(svg);

                // Add the background bars
                svg.selectAll(".background")
                    .data(data)
                    .enter()
                    .append("rect")
                    .classed("background", true);

                svg.append("line")
                    .classed("baseline", true);

                // Add the data bars
                svg.selectAll(".bar")
                    .data(data)
                    .enter()
                    .append("rect")
                    .attr("class", function (fund, i) {
                        return "bar fund" + i;
                    })
                    .style("fill", function (d, i) {
                        return (i + 1) % 3 === 0 ? pattern : "";
                    });

                svg.selectAll(".label")
                    .data(data)
                    .enter()
                    .append("text")
                    .attr("class", function (fund, i) {
                        return "label fund" + i;
                    })
                    .text(function (d) {
                        return d.value.toFixed(1) + "%"; // format with enforced decimal
                    });

                if (AG.utilities.isMobile()) {
                    horizontal(svg.datum().length);
                } else {
                    vertical(svg.datum().length);
                }
            });
        }

        function vertical(numElements) {
            var line = svg.select(".baseline"),
                bars = svg.selectAll(".bar"),
                labels = svg.selectAll(".label"),
                barWidth = 12,
                textHeight = 14,
                height = 300,
                x,
                y;

            svg.attr("width", width)
                .attr("height", height);

            labels.each(function () {
                textHeight = this.getBBox().height;
            });
            textHeight = Math.ceil(textHeight);

            x = d3.scale.ordinal()
                .rangeRoundBands([8, width - 8])
                .domain(d3.range(numElements));

            var max = Math.max(Math.abs(globalMin), Math.abs(globalMax));
            y = d3.scale.linear()
                .rangeRound([textHeight, height - textHeight])
                .domain([max, -max]);

            labels
                .attr("text-anchor", "middle")
                .attr("y", function (d) {
                    return d.value < 0 ? y(d.value) + textHeight : y(d.value) - textHeight;
                })
                .attr("dy", function (d) {
                    return d.value < 0 ? 0 : "1em";
                });

            line.attr("x1", 0)
                .attr("y1", y(0))
                .attr("x2", width)
                .attr("y2", y(0));

            bars.attr("width", barWidth)
                .attr("height", 0)
                .attr("y", y(0));

            // Manage the special case for widths if there are only 2 bars
            if (numElements === 2) {
                var startX = ((width - 8) / 2) - barWidth - 10;
                labels.attr("x", function (d, i) {
                    return startX + (i * (30 + barWidth)) + 6;
                });
                bars.attr("x", function (d, i) {
                    return startX + (i * (30 + barWidth));
                });
            } else {
                labels.attr("x", function (d, i) {
                    return x(i) + x.rangeBand() / 2;
                });
                bars.attr("x", function (d, i) {
                    return x(i) + (x.rangeBand() - barWidth) / 2;
                });
            }

            if (document.hidden) {
                bars
                    .attr("y", function (d) {
                        return d.value < 0 ? y(0) : y(d.value);
                    })
                    .attr("height", function (d) {
                        return Math.abs(y(d.value) - y(0));
                    });
            } else {
                bars.transition()
                    .duration(750)
                    .delay(function (d, i) {
                        return i * 20;
                    })
                    .attr("y", function (d) {
                        return d.value < 0 ? y(0) : y(d.value);
                    })
                    .attr("height", function (d) {
                        return Math.abs(y(d.value) - y(0));
                    });
            }
        }

        function horizontal(numElements) {
            var bars = svg.selectAll(".bar"),
                labels = svg.selectAll(".label"),
                barHeight = 7,
                barSeparatorHeight = 6,
                maxLabelWidth,
                x;

            maxLabelWidth = Math.ceil(d3.max(labels[0].map(function (label) {
                return label.getComputedTextLength();
            })));

            var height = (barHeight * numElements) + (barSeparatorHeight * (numElements - 1)) + 1;

            svg.attr("width", width)
                .attr("height", height);

            x = d3.scale.linear()
                .rangeRound([0, width])
                .domain([globalMin, globalMax]);

            labels
                .attr("x", function () {
                    return x(globalMax);
                })
                .attr("y", function (d, i) {
                    return i * (barHeight + barSeparatorHeight);
                })
                .attr("dy", "0.7em")
                .attr("text-anchor", "end");

            x.rangeRound([0, width - maxLabelWidth - 5]);

            /// UPDATE
            svg.selectAll(".background")
                .attr("x", 0)
                .attr("y", 0)
                .attr("width", x(globalMax))
                .attr("height", barHeight - 1)
                .attr("y", function (d, i) {
                    return i * (barHeight + barSeparatorHeight);
                });

            bars.attr("width", 0)
                .attr("height", barHeight)
                .attr("y", function (d, i) {
                    return i * (barHeight + barSeparatorHeight);
                })
                .attr("x", x(0));

            if (document.hidden) {
                bars
                    .attr("x", function (d) {
                        return d.value > 0 ? x(0) : x(d.value);
                    })
                    .attr("width", function (d) {
                        return Math.abs(x(d.value) - x(0));
                    });
            } else {
                bars.transition()
                    .duration(750)
                    .delay(function (d, i) {
                        return i * 20;
                    })
                    .attr("x", function (d) {
                        return d.value > 0 ? x(0) : x(d.value);
                    })
                    .attr("width", function (d) {
                        return Math.abs(x(d.value) - x(0));
                    });
            }
        }

        // Only a getter
        chart.width = function (_) {
            if (!arguments.length) {
                return width;
            }
            width = _;
            return chart;
        };

        chart.globalMin = function (_) {
            if (!arguments.length) {
                return globalMin;
            }
            globalMin = _;
            return chart;
        };

        chart.globalMax = function (_) {
            if (!arguments.length) {
                return globalMax;
            }
            globalMax = _;
            return chart;
        };

        // Getter only
        chart.height = function () {
            if (!arguments.length) {
                return height;
            }
            return chart;
        };

        chart.width(width);
        chart.globalMin(globalMin);
        chart.globalMax(globalMax);

        return chart;
    };

    return {
        verticalSplitBarGraph: verticalSplitBarGraph,
        horizontalSplitBarGraph: horizontalSplitBarGraph,
        spacedBarGraph: spacedBarGraph
    };
})(d3, accounting);
