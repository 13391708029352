var jQuery = require('jquery');

window.AG = window.AG || {};

AG.forms = (function ($) {
    "use strict";

    $(".material.switch").on("click", "label", function (event) {
        event.preventDefault();
        var $clickedElem = $(event.target),
            $label = $clickedElem.closest("label"),
            $checkbox = $label.find("input[type=checkbox]");

        if (!$clickedElem.closest(".ag-tooltip-trigger").length) {
            $checkbox[0].checked = !$checkbox[0].checked;
            $checkbox.trigger("change");
        }
    });

    $(".checkbox--material").on("click", "label", function (event) {
        event.preventDefault();
        var $clickedElem = $(event.target),
            $label = $clickedElem.closest("label"),
            $checkbox = $label.prev("input[type=checkbox]");
        $checkbox[0].checked = !$checkbox[0].checked;
        $checkbox.focus().trigger("change");
    });

    $(document).on("input", "input, textarea", function (event) {
        var input = event.target,
            inputLength = input.value.trim().length,
            $closestWrapper = $(input).closest(".input");

        if (inputLength === 0) {
            $closestWrapper.removeClass("filled");
        }
        else {
            $closestWrapper.addClass("filled");
        }
    });


    (function ($) {
        $(".input > input, .input > textarea").each(function (i, e) {
            $(e).trigger("input");
        });
    }(jQuery));

    function initCustomDropdowns() {

        // custom select dropdown (only the trigger element styled)
        $('[data-widget="CustomDropdown"]').each(function () {

            // ensure only init once
            if (!$(this).data('init')) {
                $(this).data('init', true);

                var $this = $(this);
                var $select = $this.find('select');

                $this
                    .append('<div class="custom-dropdown__value"></div>')
                    .append('<div class="custom-dropdown__arrow"><span></span></div>');

                var $val = $this.find('.custom-dropdown__value');

                // set label
                $val.text($select.find(':selected').text());

                // add init style
                if ($select.find(':selected').val() == '') {
                    $val.addClass('custom-dropdown__value--init');
                }


                // on change, remove init style if has value
                $select.change(function () {
                    $val.text($select.find(':selected').text());

                    if ($select.find(':selected').val() !== '') {
                        $val.removeClass('custom-dropdown__value--init');
                    } else {
                        $val.addClass('custom-dropdown__value--init');
                    }

                });

            }

        });

    }

    function initCustomSelect(params) {


        $('[data-widget="CustomSelect"]').each(function () {

            var $select = $(this);

            if ($select.data('init')) return;
            $select.data('init', true);

            // set default button colour based on data-widget-button-colour
            var buttonColour = $select.data('widget-button-colour') || 'button--secondary-grey';
            var selectedOptionText = $select.find('option:selected').text();
            var selectedOptionVal = $select.val();
            var listId = $select.data('list-id');

            var dropdownHTML = `
            <div class="dropdown dropdown--full-width">             
                <button class="button button--block ${buttonColour}" type="button" data-toggle="dropdown" aria-expanded="true">
                    <span class="value">${selectedOptionText}</span>
                    <i class="agi agi-f-chevron-right rotate-90"></i>
                </button>                
                <ul class="dropdown-menu" role="menu" id=${listId}>`;

            $select.find('option').each(function () {
                var text = $(this).text();
                var val = $(this).val();
                dropdownHTML += `<li><a data-value="${val}" ${val === selectedOptionVal ? 'class="is-active"' : ''}>${text}</a></li>`;
            });


            dropdownHTML +=
                `</ul>                    
                </div>`
                ;

            $select.addClass('hide').after(dropdownHTML);


            $select.next('.dropdown').find('li a').on('click', function () {
                var $this = $(this);
                $this.addClass('is-active');
                $this.parent('li').siblings('li').find('a').removeClass('is-active');
                $this.closest('.dropdown').find(".button .value").text($this.text());

                // set hidden input value
                // $this.closest('.dropdown').find('input[type="text"]').val($this.data('value'));

                // set select value
                $select.val($this.data('value')).trigger('change');

                if (typeof callback === "function") {
                    callback('updated');
                }

            });


        });
    }


    return {
        initCustomDropdowns: initCustomDropdowns,
        initCustomSelect: initCustomSelect
    }


}(jQuery));
