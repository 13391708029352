var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.cookienotice = (function ($) {
    "use strict";

    var activateCookieNotice = function () {

        var $cookieNotice = $(".cookie-notice");
        if (!$cookieNotice.length) return false;

        // localstorage id string
        var cookieNoticeId = "cookienotice_" + $cookieNotice.data('noticeid');

        // 90 days default unless specified via data-noticeexpire
        var defaultDays = 90;
        var expirationInSeconds = $cookieNotice.data('noticeexpire');
        var expirationDuration = expirationInSeconds ? 1000 * parseInt(expirationInSeconds) : 1000 * 60 * 60 * 24 * defaultDays;


        // if notice previously existed
        if (localStorage.getItem(cookieNoticeId)) {

            var currentTime = new Date().getTime();
            var acceptedTime = parseInt(localStorage.getItem(cookieNoticeId));

            // if timestamp not in expected format, show notice
            if (isNaN(acceptedTime) || acceptedTime == null) {

                $cookieNotice.addClass('is-active');

            } else {

                if ((currentTime - acceptedTime) < expirationDuration) {
                    // within time limit, remove
                    $cookieNotice.remove();
                } else {
                    // over time limit, show again
                    $cookieNotice.addClass('is-active');
                }

            }

            // else new notice
        } else {
            $cookieNotice.addClass('is-active');
        }


        $cookieNotice.find('[data-close="cookie-notice"]').on('click', function (e) {
            e.preventDefault();

            // set timestamp when closed
            localStorage.setItem(cookieNoticeId, new Date().getTime());
            $cookieNotice.remove();
        });



    };

    return {
        activateCookieNotice: activateCookieNotice
    };

}(jQuery));
