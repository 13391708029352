var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.video = (function($) {
    "use strict";

    var $modal, 
        $container, 
        $videoStage,
        $galleryContainer,      
        youTubePlayer,
        vimeoPlayer,
        playerType;

    var pfx = ["webkit", "moz", "ms", "o", ""];

    function RunPrefixMethod(obj, method) {
        var p = 0,
            m, t;
        while (p < pfx.length && !obj[m]) {
            m = method;
            if (pfx[p] == "") {
                m = m.substr(0, 1).toLowerCase() + m.substr(1);
            }
            m = pfx[p] + m;
            t = typeof obj[m];
            if (t != "undefined") {
                pfx = [pfx[p]];
                return (t == "function" ? obj[m]() : obj[m]);
            }
            p++;
        }
    }

    

    /**
     * @param {Object} params The parameters described below
     * selector {id/class/selector} for the component to use to get the Video parameters
     * onOpen The function to run before the video modal is opened
     * onClose The function to run before the video modal is closed
     */
    var setupVideoModal = function(params) {

        $(document).on('click', params.selector, function(event){
            event.preventDefault();
           
            var videoId = $(this).data("video-id"),
            videoGalleryExists = $(this).closest('[data-video-gallery]').length ? true : false;

            // set player type (youtube or vimeo, defaults to youtube)
            playerType = 'youtube';
            if( $(this).attr('data-video-player') ) playerType = $(this).attr('data-video-player');


            $modal = $(".video-modal"),
            $container = $modal.find(".video-modal__container"),
            $videoStage = $container.find("#js-video-modal-stage");

            if (params.onOpen) {
                params.onOpen();
            }
            $container.show();
            $modal.fadeIn();

            if ($videoStage.length === 0) {
                $container.append('<div id="js-video-modal-stage"></div>');
            }
            

            if (playerType == 'vimeo'){           

                try {

                    vimeoPlayer = new Vimeo.Player('js-video-modal-stage', {
                        id: videoId,
                        width: '100%'
                    });

                    vimeoPlayer.ready().then(function() {
                    
                        if (!AG.utilities.isMobile() && !AG.utilities.isTouch()) {
                            vimeoPlayer.play();
                        }

                    });

                } catch (err) {
                    console.log("Failed to 'PLAY'  Video for section. Error: ", err);
                }

                $modal.find('.video-modal__close-control').off('click').on('click', function(){                    
                    try {
                        vimeoPlayer.pause();
                        vimeoPlayer.destroy();
                    } catch (err) {
                        console.log("Failed to 'STOP' Vimeo Video for section. Error: ", err);
                    }
                    $container.hide();
                    $modal.fadeOut("slow");
                    if (params.onClose) {
                        params.onClose();
                    }
                }); 


            // else default to youtube
            } else {

                try {

                    youTubePlayer = new YT.Player("js-video-modal-stage", {
                        videoId: videoId,
                        width: '100%',
                        height: '100%',
                        playerVars: {
                            autoplay: 1,
                            controls: 1,
                            enablejsapi: 1,
                            fs: 1,
                            showinfo: 0,
                            rel: 0
                        },
                        events: {
                            // The API will call this function when the video player is ready.
                            'onReady': function(event) {
                                //youTubePlayer = event.target;
                                youTubePlayer.setPlaybackQuality("highres");
                                if (!AG.utilities.isMobile() && !AG.utilities.isTouch()) {
                                    youTubePlayer.playVideo();
                                }
                                
                            }
                        }
                    });

                } catch (err) {
                    console.log("Failed to 'PLAY'  Video for section. Error: ", err);
                } 

                $modal.find('.video-modal__close-control').off('click').on('click', function(){
                    try {
                        youTubePlayer.stopVideo();
                        youTubePlayer.destroy();
                    } catch (err) {
                        console.log("Failed to 'STOP' Youtube Video for section. Error: ", err);
                    }
                    $container.hide();
                    $modal.fadeOut("slow");
                    if (params.onClose) {
                        params.onClose();
                    }
                });
            }
                      
           
            /// currently supports youtube videos only
            if(videoGalleryExists && playerType === 'youtube'){               
                $galleryContainer = $(this).closest('[data-video-gallery]');
                if($galleryContainer.find('[data-video-id]').length > 1){
                    setupGallery(params, videoId);
                }
            }
            
        });
    };

    var getGalleryPos = function(videoId){
        return $galleryContainer.find('[data-video-id]').index( $galleryContainer.find('[data-video-id='+videoId+']') );        
    }

    var setupGallery = function(params, videoId){

        $modal
            .append('<div class="video-modal__arrow video-modal__arrow-next"><span class="agi agi-chevron-right"></span></div>')
            .append('<div class="video-modal__arrow video-modal__arrow-prev"><span class="agi agi-chevron-left"></span></div>');


        var galleryLength = $galleryContainer.find('[data-video-id]').length;

        // on next click
        $modal.on('click', '.video-modal__arrow-next', function(){

            var currentVideoIdx = getGalleryPos(videoId);

            if(currentVideoIdx < (galleryLength-1))
                var nextVideoIdx = currentVideoIdx + 1;
            else
                var nextVideoIdx = 0;

            videoId = $galleryContainer.find('[data-video-id]:eq('+nextVideoIdx+')').data('video-id');
            youTubePlayer.loadVideoById(videoId);
            
        });

        // on prev click
        $modal.on('click', '.video-modal__arrow-prev', function(){

            var currentVideoIdx = getGalleryPos(videoId);

            if(currentVideoIdx > 0)
                var nextVideoIdx = currentVideoIdx - 1;
            else
                var nextVideoIdx = (galleryLength-1);

            videoId = $galleryContainer.find('[data-video-id]:eq('+nextVideoIdx+')').data('video-id');
            youTubePlayer.loadVideoById(videoId);

        });
    }


    return {
        setupVideoModal: setupVideoModal
    };

}(jQuery));
