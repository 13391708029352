window.AG = window.AG || {};
AG.WEBFONTS_ACTIVE = false;

AG.fontMerriweather = new FontFaceObserver('Merriweather');
AG.fontRobotoSlab = new FontFaceObserver('Roboto Slab');
AG.fontRoboto = new FontFaceObserver('Roboto');

Promise.all([AG.fontMerriweather.load(), AG.fontRobotoSlab.load(), AG.fontRoboto.load()]).then(function () {
    // console.log('All fonts have loaded');
    AG.WEBFONTS_ACTIVE = true;
});