// console.log('index.js');

require('./index.scss');
// included via webpack entry points instead
// require('./styles/home.scss');
// require('./styles/microsites.scss');


// babel polyfills (if necessary)
// require('core-js/stable');
// require('regenerator-runtime/runtime');

// polyfills
import 'promise-polyfill/src/polyfill';

// NPM
window.$ = window.jQuery = require('jquery'); // setting globally because modules were not written correctly to take in $. TODO: Fix this.

// Modify jquery event listeners to passive
// https://stackoverflow.com/questions/60357083/does-not-use-passive-listeners-to-improve-scrolling-performance-lighthouse-repo
jQuery.event.special.touchstart = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.touchmove = {
  setup: function (_, ns, handle) {
    this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
  }
};
jQuery.event.special.wheel = {
  setup: function (_, ns, handle) {
    this.addEventListener("wheel", handle, { passive: true });
  }
};
jQuery.event.special.mousewheel = {
  setup: function (_, ns, handle) {
    this.addEventListener("mousewheel", handle, { passive: true });
  }
}

window.moment = require('moment'); // setting globally because modules were not written correctly to take in $. TODO: Fix this.
window.accounting = require('accounting'); // setting globally because modules were not written correctly to take in $. TODO: Fix this.
window._ = window.underscore = require('underscore'); //setting globally because modules were not written correctly to take in $. TODO: Fix this.
//window.FontFaceObserver = require('fontfaceobserver/fontfaceobserver.js'); // includes promise polyfill
window.FontFaceObserver = require('fontfaceobserver');
window.d3 = require('d3'); // setting globally because modules were not written correctly to take in $. TODO: Fix this.
window.nod = require('nod-validate');
require('bootstrap-sass');
require('bootstrap-datepicker');
require('jquery.animate-number');

// Local libs
require('./libs/slick-1.8.0/slick.js'); // importing this from libs because the npm version has a bug, see https://github.com/kenwheeler/slick/issues/3110
require('./libs/fullpage.js/jquery.fullpage.mod.js'); // v2.7.9 with a manual mod to patch a security vulnerability, see file for details.

// App
require('./scripts/ag.constants.js');
require('./scripts/ag.utilities.js');
require('./scripts/ag.d3.js');
require('./scripts/ag.d3.behaviours.js');
require('./scripts/ag.d3.chart.bar.js');
require('./scripts/ag.d3.chart.performance.js');
require('./scripts/ag.d3.components.js');
require('./scripts/ag.d3.constants.js');
require('./scripts/ag.d3.utilities.js');
//require('./scripts/ag.footer.js');
require('./scripts/ag.forms.js');
require('./scripts/ag.ui.js');
require('./scripts/ag.ui.jquery-element-visible.js');
require('./scripts/ag.ui.modals.js');
require('./scripts/ag.ui.navigation.js');
require('./scripts/ag.ui.quarterselector.js')
require('./scripts/ag.ui.socialmedia.js');
require('./scripts/ag.ui.tables.js');
require('./scripts/ag.ui.tooltips.js');
require('./scripts/ag.ui.video.js');
require('./scripts/ag.ui.video-custom.js');
require('./scripts/ag.validation.js');
require('./scripts/ag.info.note.js'); // unused
require('./scripts/collapse-extended.js');
require('./scripts/ag.ui.globalnotice.js');
require('./scripts/ag.ui.cookienotice.js');
require('./scripts/ag.ui.fontsloaded.js');

// General global init



$(function () {
  AG.ui.navigation.activateMenu();
  AG.ui.navigation.activateFooter();
  AG.ui.socialmedia.activate();
  AG.forms.initCustomDropdowns();
  AG.forms.initCustomSelect();

  // standard tab nav carousel init
  $(".js-responsive-carousel:not(.menu-carousel--custom)").each(function (i, e) {
    AG.ui.navigation.initCarouselMenu(
      $(e),
      null,
      null,
      function (tabId, event) {
        // global tab analytics tracking helper function
        if (typeof recordTabEvent === "function") recordTabEvent(tabId, event);
      }
    );
  });

  AG.ui.navigation.activateAnchorScroll();
  AG.ui.globalnotice.activateGlobalNotice();
  AG.ui.cookienotice.activateCookieNotice();
  AG.ui.tooltips.init();
  AG.ui.setScrollbarWidthVar();
  AG.ui.videoCustom.initCustomVideos({
    videoStarted: function (videoTitle, videoId) {
      if (typeof recordCustomVideoStart === "function") recordCustomVideoStart(videoTitle, videoId);
    }
  });
});

