var jQuery = require('jquery');

window.AG = window.AG || {};

AG.validation = (function ($) {
  "use strict";

  function restrictPhoneNumber($element) {

    var phoneNumberRestrictions = {
      allowed: [8, 9, 13, 16, 17, 18, 19, 20, 32, 33, 34, 35, 36, 37, 38, 39, 40, 44, 45, 46, 145, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 107, 187],
      illegalReg: [106, 111, 191, 220],
      illegalShift: [56, 59, 188, 190, 191, 220, 222]
    }

    $element.on("keydown", function (e) {
      var eKey = e.which || e.keyCode;
      if (phoneNumberRestrictions.allowed.indexOf(eKey) === -1) return false;
      if (phoneNumberRestrictions.illegalReg.indexOf(eKey) > -1) return false;
      if (e.shiftKey && phoneNumberRestrictions.illegalShift.indexOf(eKey) > -1) return false;
    });
  }

  /**
   * Strips all non-alphabetical characters from a string.
   *
   * @return {string} The new string with characters removed.
   */
  var stripNonAlphabetical = function (string) {
    return string.replace(/[\d\W\s]/g, "");
  };

  var initialiseContactForm = function ($contactForms) {
    if ($contactForms === undefined) {
      $contactForms = $(".js-contact-form");
    }

    _.each($contactForms, function (contactForm) {
      var n = nod();

      n.configure({
        submit: $(contactForm).find('.js-validate-submit'),
        disableSubmit: true,
        delay: 250,
        errorClass: "error",
        errorMessageClass: 'error-message'
      });

      restrictPhoneNumber($(".js-validate-phone-number"));

      n.add([{
        selector: $(contactForm).find('.js-validate-name'),
        validate: 'presence',
        errorMessage: 'Please enter your name',
        defaultStatus: 'invalid'
      }, {
        selector: $(contactForm).find('.js-validate-surname'),
        validate: 'presence',
        errorMessage: 'Please enter your surname',
        defaultStatus: 'invalid'
      }, {
        selector: $(contactForm).find('.js-validate-phone-number'),
        validate: 'presence',
        errorMessage: 'Please enter your phone number'
      }, {
        selector: $(contactForm).find('.js-validate-email'),
        validate: ['presence', 'email'],
        errorMessage: ['Please enter your email address', 'Email address is invalid']
      }, {
        selector: $(contactForm).find('.js-validate-email-strict'),
        validate: ['presence', function (callback, value) {
          var emailExp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);
          var filtered = value.match(emailExp);
          callback(filtered);
        }],
        errorMessage: ['Please enter your email address', 'Email address is invalid']
      }, {
        selector: $(contactForm).find('.js-validate-comments'),
        validate: 'presence',
        errorMessage: 'Please enter your message',
        defaultStatus: 'invalid'
      }, {
        selector: $(contactForm).find('.js-validate-title'),
        validate: 'presence',
        errorMessage: 'Please enter your title',
        defaultStatus: 'invalid'
      },
      // two optional fields from splash pages
      {
        selector: $(contactForm).find('.js-validate-company'),
        validate: 'presence',
        errorMessage: 'Please enter your company name',
        defaultStatus: 'invalid'
      }
      ]);

      $(contactForm).submit(function (event) {
        event.preventDefault();
        n.performCheck();

        setTimeout(function () {
          submitBtn.innerHTML = onSuccess;
          $("input, textarea").trigger("blur");
        }, 1000);

        if (n.areAll(nod.constants.VALID)) {
          var form = event.target,
            submitBtn = form.querySelector("button[type=submit]"),
            onProgress = $(submitBtn).data("onprogress"),
            onSuccess = $(submitBtn).data("onsuccess");

          submitBtn.setAttribute("disabled", true);
          submitBtn.innerHTML = onProgress;
        }
      });

      $(contactForm).on("focus", "input, textarea", function (event) {
        var form = $(event.target).closest("form"),
          submitBtn = $(form).find("button[type=submit]"),
          onDefault = $(submitBtn).data("ondefault");
        submitBtn.innerHTML = onDefault;
      });

    });

  };

  var initialiseSmsSubscriptionForm = function ($subscriptionForm) {
    if ($subscriptionForm === undefined) {
      $subscriptionForm = $(".js-contact-form");
    }

    _.each($subscriptionForm, function (form) {
      var n = nod();

      n.configure({
        submit: $(form).find('.js-validate-submit'),
        disableSubmit: true,
        delay: 250,
        errorClass: "error",
        errorMessageClass: 'error-message'
      });

      restrictPhoneNumber($(".js-validate-mobile-number"));

      n.add([
        {
          selector: $(form).find('.js-validate-name'),
          validate: 'presence',
          errorMessage: 'Please enter your name',
          defaultStatus: 'invalid'
        },
        {
          selector: $(form).find('.js-validate-lastname'),
          validate: 'presence',
          errorMessage: 'Please enter your surname',
          defaultStatus: 'invalid'
        },
        {
          selector: $(form).find('.js-validate-mobile-number'),
          validate: 'presence',
          errorMessage: 'Please enter your phone number',
          defaultStatus: 'invalid'
        },
        {
          selector: $(form).find('.js-validate-email'),
          validate: ['email'],
          errorMessage: ['Email address is invalid'],
          defaultStatus: 'valid'
        },
        {
          selector: $(form).find('.js-validate-title'),
          validate: 'presence',
          errorMessage: 'Please enter your title',
          // defaultStatus: 'valid',
          // triggeredBy: '.dropdown-toggle'
        }
      ]);

      $(form).submit(function (event) {
        event.preventDefault();
        n.performCheck();

        setTimeout(function () {
          submitBtn.innerHTML = onSuccess;
          $("input, textarea").trigger("blur");
        }, 1000);

        if (n.areAll(nod.constants.VALID)) {
          var form = event.target,
            submitBtn = form.querySelector("button[type=submit]"),
            onProgress = $(submitBtn).data("onprogress"),
            onSuccess = $(submitBtn).data("onsuccess");

          submitBtn.setAttribute("disabled", true);
          submitBtn.innerHTML = onProgress;
        }
      });

      $(form).on("focus", "input, textarea", function (event) {
        var form = $(event.target).closest("form"),
          submitBtn = $(form).find("button[type=submit]"),
          onDefault = $(submitBtn).data("ondefault");
        submitBtn.innerHTML = onDefault;
      });

    });

  };

  var initialiseInsightsForm = function ($insightsForms) {
    if ($insightsForms === undefined) {
      $insightsForms = $(".js-insights-form");
    }

    _.each($insightsForms, function (insightsForm) {
      var n = nod();

      n.configure({
        jQuery: $,
        submit: $(insightsForm).find('.js-validate-submit'),
        disableSubmit: true,
        delay: 500,
        errorClass: "error",
        errorMessageClass: 'error-message'
      });


      n.add([{
        selector: $(insightsForm).find('.js-validate-name'),
        validate: ['presence', 'min-length:2'],
        errorMessage: ['Please enter your name', 'Name should be at least 2 characters long']
      }, {
        selector: $(insightsForm).find('.js-validate-surname'),
        validate: ['presence', 'min-length:2'],
        errorMessage: ['Please enter your surname', 'Surname should be at least 2 characters long']
      }, {
        selector: $(insightsForm).find('.js-validate-email-strict'),
        validate: ['presence', function (callback, value) {
          var emailExp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);
          var filtered = value.match(emailExp);
          callback(filtered);
        }],
        errorMessage: ['Please enter your email address', 'Email address is invalid']
      }
      ]);

      $(insightsForm).submit(function (event) {
        event.preventDefault();
        n.performCheck();

        var form = event.target,
          submitBtn = $(form).find("button[type=submit]"),
          onSuccess = $(submitBtn).data("onsuccess"),
          onProgress = $(submitBtn).data("onprogress");

        // if (n.areAll(nod.constants.VALID)) { 
        //   $(insightsForm).addClass('is-success');
        //   $(onSuccess).addClass('is-active');          
        // }

        // $(onSuccess).addClass('is-active');

        submitBtn[0].setAttribute("disabled", true);
        submitBtn[0].innerHTML = onProgress;

      });


      $(insightsForm).find('.js-validate-toggle-fields').change(function () {

        var $init = $($(this).data('init'));
        var $tar = $($(this).data('target'));

        // if checked
        if ($(this).prop('checked')) {
          $tar.addClass('is-active');
          $tar.find('.form-control').removeAttr('tabindex');

          n.add([{
            selector: $(insightsForm).find('.js-validate-phone-number'),
            validate: ['presence', function (callback, value) {
              var phoneExp = new RegExp(/^[0][6-8]([0-9]{8})$/g);
              var filtered = value.match(phoneExp);
              callback(filtered);
            }],
            errorMessage: ['Please enter your SA cellphone number', 'Cellphone number should contain no spaces nor a country code']
          }
          ]);

          // trigger validation if not empty
          $tar.find('.form-control').each(function () {
            if ($(this).val().length > 0) $(this).trigger('change');
          });


          // if unchecked
        } else {
          $tar.removeClass('is-active');
          $tar.find('.form-control').attr('tabindex', "-1");

          n.remove([
            '.js-validate-phone-number'
          ]);

          // trigger validation
          $init.find('.form-control').each(function () {
            if ($(this).val().length > 0) $(this).trigger('change');
          });

          // n.performCheck();          

        }
      });



    });

  };

  var initialiseStdForm = function ($form) {
    if ($form === undefined) {
      $form = $(".js-std-form");
    }

    _.each($form, function (form) {
      var n = nod();

      n.configure({
        submit: $(form).find('.js-validate-submit'),
        disableSubmit: true,
        delay: 700,
        errorClass: "error",
        errorMessageClass: 'error-message'
      });

      restrictPhoneNumber($(".js-validate-phone-number"));

      n.add([{
        selector: $(form).find('.js-validate-name'),
        validate: ['presence', 'min-length:2'],
        errorMessage: ['Please enter your name', 'Name should be at least 2 characters long'],
        defaultStatus: 'invalid'
      }, {
        selector: $(form).find('.js-validate-surname'),
        validate: ['presence', 'min-length:2'],
        errorMessage: ['Please enter your surname', 'Surname should be at least 2 characters long'],
        defaultStatus: 'invalid'
      }, {
        selector: $(form).find('.js-validate-title'),
        validate: ['presence', 'min-length:2'],
        errorMessage: ['Please enter your title', 'Title should be at least 2 characters long'],
        defaultStatus: 'invalid'
      }, {
        selector: $(form).find('.js-validate-phone-number'),
        validate: ['presence', 'min-length:10'],
        errorMessage: ['Please enter your phone number', 'Phone number should be at least 10 digits long']
      }, {
        selector: $(form).find('.js-validate-email'),
        validate: ['presence', 'email'],
        errorMessage: ['Please enter your email address', 'Email address is invalid']
      }, {
        selector: $(form).find('.js-validate-email-strict'),
        validate: ['presence', function (callback, value) {
          var emailExp = new RegExp(/\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b/i);
          var filtered = value.match(emailExp);
          callback(filtered);
        }],
        errorMessage: ['Please enter your email address', 'Email address is invalid']
      }, {
        selector: $(form).find('.js-validate-textarea'),
        validate: ['presence'],
        errorMessage: ['Please enter your message'],
        defaultStatus: 'invalid'
      }, {
        selector: $(form).find('.js-validate-dob'),
        validate: ['presence', function (callback, value) {
          var date = moment(value, "DD / MM / YYYY", true).isValid();
          callback(date);
        }],
        errorMessage: ['Please enter your date of birth', 'Please enter a valid date (dd / mm / yyyy)']
      }
      ]);

      $(form).on('submit', function (event) {
        event.preventDefault();
        n.performCheck();

        // if all valid, show progress text in submit button
        if (n.areAll(nod.constants.VALID)) {
          var form = event.target,
            submitBtn = form.querySelector("button[type=submit]"),
            onProgress = $(submitBtn).data("onprogress");

          submitBtn.setAttribute("disabled", true);
          submitBtn.innerHTML = onProgress;
        }
      });

      // reset submit button state on any field select
      $(form).on("focus", "input, textarea", function (event) {
        var form = $(event.target).closest("form"),
          submitBtn = $(form).find("button[type=submit]")[0],
          onDefault = $(submitBtn).data("ondefault");
        submitBtn.innerHTML = onDefault;
      });

    });

  };

  return {
    stripNonAlphabetical: stripNonAlphabetical,
    initialiseContactForm: initialiseContactForm,
    initialiseSmsSubscriptionForm: initialiseSmsSubscriptionForm,
    initialiseInsightsForm: initialiseInsightsForm,
    initialiseStdForm: initialiseStdForm
  };

}(jQuery));
