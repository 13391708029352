var d3 = require('d3');
var moment = require('moment');
var _ = require('underscore');

window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};

AG.d3.utilities = (function (d3, moment, _) {
    "use strict";

    var transformTimeSeriesData = function (data, exclusionKeys) {
        data = _.chain(data)
            .map(function (item) {
                item.date = moment("01-" + item.month + "-" + item.year, "DD-MM-YYYY").toDate();
                return item;
            })
            .sortBy(function (item) {
                return item.date;
            })
            .value();

        return d3.keys(data[0]).filter(function (key) {
            return exclusionKeys.indexOf(key) < 0;
        })
            .map(function (d) {
                return data.map(function (dd) {
                    return {
                        date: dd.date,
                        value: parseFloat(dd[d])
                    };
                });
            });
    };

    return {
        calculateLayersMax: function (layers, func) {
            return d3.max(layers, function (layer) {
                return d3.max(layer.data, func);
            });
        },
        calculateLayersMin: function (layers, func) {
            return d3.min(layers, function (layer) {
                return d3.min(layer.data, func);
            });
        },
        classify: function (label) {
            return label.toLowerCase().trim().replace(/ /g, "-").replace(/\//g, "-");
        },
        /*******************************************************************************************************
         *   http://stackoverflow.com/questions/13740164/d3-js-transition-not-working-when-browser-is-minimised
         *******************************************************************************************************/
        transition: function (selection, durationMs) {
            return (document.hidden ? selection : selection.transition().duration(durationMs));
        },

        appendStripePattern: function (svg) {
            var patternName = new Date().getTime() + "-pattern";
            var defs = svg.append("defs");
            var pattern = defs
                .append("pattern")
                .attr("id", patternName)
                .attr("class", "pattern-stripe")
                .attr("patternUnits", "userSpaceOnUse")
                .attr("width", 3)
                .attr("height", 3);
            pattern.append("rect").attr("x", 0).attr("y", 0).attr("width", 1).attr("height", 1);
            pattern.append("rect").attr("x", 1).attr("y", 2).attr("width", 1).attr("height", 1);
            pattern.append("rect").attr("x", 2).attr("y", 1).attr("width", 1).attr("height", 1);
            return "url(#" + patternName + ")";
        },

        extractLegendData: function (dataRow, exclusionKeys) {
            return d3.keys(dataRow).filter(function (key) {
                return exclusionKeys.indexOf(key) < 0;
            }).map(function (key) {
                return {
                    name: key,
                    value: dataRow[key]
                };
            });
        },



        formatDataForDraw: function (csv, legendTitle, draw) {
            d3.csv(csv, function (data) {
                var legendData = data.slice(0, 2),
                    // Remove the 1st row with the legend information
                    transformedData = transformTimeSeriesData(data.slice(legendData.length), ["date", "month", "year"]),
                    inflationLegend = [],
                    adjustedLegend = [],
                    inflationData = [],
                    adjustedData = [],
                    adjustedFirst;

                legendData = AG.d3.utilities.extractLegendData(legendData[0], ["date", "month", "year"])
                    .map(function (obj) {
                        return {
                            name: obj.name,
                            values: [
                                legendData[0][obj.name],
                                legendData[1][obj.name]
                            ]
                        };
                    });

                legendData.forEach(function (fund, i) {
                    if (i < (legendData.length / 2)) {
                        inflationLegend.push(fund)
                    } else {
                        adjustedLegend.push(fund)
                    }
                });
                var heading = {
                    name: legendTitle,
                    values: [
                        "Cumulative",
                        "Annualised"
                    ]
                };
                inflationLegend.unshift(heading);
                adjustedLegend.unshift(heading);

                var tempMin,
                    tempMax,
                    min = 10,
                    max = 0;
                transformedData.forEach(function (fund, i) {
                    tempMin = d3.min(fund, function (entry) {
                        return Math.floor(entry.value);
                    });
                    tempMax = d3.max(fund, function (entry) {
                        return Math.ceil(entry.value);
                    });
                    min = Math.min(min, tempMin);
                    max = Math.max(max, tempMax);
                    if (i < (transformedData.length / 2)) {
                        inflationData.push(fund)
                    } else {
                        adjustedData.push(fund)
                    }
                });
                adjustedFirst = adjustedData.concat(inflationData);

                draw({
                    inflationLegend: inflationLegend,
                    adjustedLegend: adjustedLegend,
                    inflationData: inflationData,
                    adjustedFirst: adjustedFirst,
                    min: min,
                    max: max
                });
            });
        }
    };
})(d3, moment, _);
