var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui = (function($) {
    "use strict";
    var activatePrint = function() {
        $(".print-button").on("click", function() {
            window.print();
        });
    };

    var setScrollbarWidthVar = function () {
        var body = document.querySelector("body");
        var scrollbar = window.innerWidth - body.clientWidth;
        body.setAttribute("style", `--scrollbar-width: ${scrollbar}px`);
    }

    return {
        activatePrint: activatePrint,
        setScrollbarWidthVar: setScrollbarWidthVar
    };
}(jQuery));
