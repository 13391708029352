var d3 = require('d3');
var accounting = require('accounting');

window.AG = window.AG || {};
window.AG.d3 = window.AG.d3 || {};
AG.d3.chart = AG.d3.chart || {};

AG.d3.chart.bar = (function (d3, accounting) {
    "use strict";

    return function () {
        var width,
            height,
            layout,
            barProperties,
            labelProperties,
            labelFormatters;

        var type;

        var svg,
            components,
            x,
            xBars;

        var _relativeMin,
            _relativeMax,
            _groupedHeight,
            _groupedHeightShift,
            _stackedHeight,
            _rightLabelSpace = 10,
            _spacer;

        function chart(selection) {
            selection.each(function (data) {
                if (data === undefined) {
                    console.log("!!! Failed to render Bar graph. Data is undefined");
                    return;
                }
                // Calculate all the values required to create the graph
                components = transformData(data);

                if (components.numSamples > 0) {
                    svg = d3.select(this);
                    if (!svg.classed("bargraph")) {
                        // Set the chart dimensions
                        calculateDimensions();
                        // Create the chart
                        svg.classed("bargraph", true);

                        // Create the backgrounds
                        svg.append("g")
                            .classed("background", true)
                            .selectAll("rect")
                            .data(components.firstDataLayer)
                            .enter()
                            .append("rect")
                            .attr("x", 0);

                        // Create the background on top
                        svg.append("g")
                            .classed("background-top", true)
                            .selectAll("rect")
                            .data(components.firstDataLayer)
                            .enter()
                            .append("rect")
                            .attr("x", 1);

                        // Create the data bars
                        svg.selectAll(".layer")
                            .data(components.data)
                            .enter()
                            .append("g")
                            .attr("class", function (d) {
                                return AG.d3.utilities.classify(d.layer) + " layer";
                            })
                            .selectAll("rect")
                            .data(function (d) {
                                return d.data;
                            })
                            .enter()
                            .append("rect")
                            .attr("class", function (d) {
                                return AG.d3.utilities.classify(d.label);
                            })
                            .selectAll("rect")
                            .attr("width", 0)
                            .attr("x", xBars(0));

                        // Create the major/global labels
                        svg.append("g")
                            .classed("global-labels", true)
                            .selectAll("text")
                            .data(components.firstDataLayer)
                            .enter()
                            .append("text")
                            .text(function (d) {
                                return d.label;
                            });

                        // Create the grouped labels
                        var text = svg
                            .selectAll(".grouped-labels")
                            .data(components.data)
                            .enter()
                            .append("g")
                            .classed("grouped-labels", true)
                            .selectAll("text")
                            .data(function (d) {
                                return d.data;
                            })
                            .enter()
                            .append("text")
                            .text(function (d) {
                                return labelFormatters.dataFormatter(d.y);
                            })
                            .attr("dy", "0.4em");


                        /// ---
                        if (labelProperties.domainLocation === AG.d3.constants.LABEL_LOCATION.INSIDE_RIGHT) {
                            text.append("tspan")
                                .classed("grouping-label", true)
                                .text(function (d) {
                                    return " " + d.group;
                                });
                        }
                        /// ---
                        // Create the stacked labels
                        svg
                            .selectAll(".stacked-labels")
                            // Only render the last layer for stacked
                            .data([components.data[components.data.length - 1]])
                            .enter()
                            .append("g")
                            .classed("stacked-labels", true)
                            .selectAll("text")
                            .data(function (d) {
                                return d.data;
                            })
                            .enter()
                            .append("text")
                            .text(function (d) {
                                return labelFormatters.dataFormatter(d.y + d.y0);
                            });
                    }
                    chart.resize();
                }
            });
        }

        function transformData(data) {
            // The relative values are used for sets of graphs which need to be positioned relatively
            if (data.relativeMin) {
                _relativeMin = data.relativeMin;
                delete data.relativeMin;
            }
            if (data.relativeMax) {
                _relativeMax = data.relativeMax;
                delete data.relativeMax;
            }
            // Get the DataSet keys
            var keys = d3.keys(data[0]).filter(function (key) {
                return key !== "label";
            });

            var numLayers = keys.length;

            var stack = d3.layout.stack();
            var layers = stack(d3.range(numLayers).map(function (layerIndex) {
                // Filter zero values items if configured
                return data.filter(function (d) {
                    if (barProperties.removeIfEmpty) {
                        return parseFloat(d[keys[layerIndex]]) !== 0;
                    }
                    return true;
                }).map(function (d, i) {
                    return {
                        group: keys[layerIndex],
                        label: d.label,
                        x: i,
                        y: parseFloat(d[keys[layerIndex]])
                    };
                });
            })).map(function (d, i) {
                return {
                    layer: keys[i],
                    data: d
                };
            });

            var numSamples = d3.max(layers, function (layer) {
                return layer.data.length;
            });

            function groupMinMaxFunc(d) {
                return d.y;
            }

            function stackMinMaxFunc(d) {
                return d.y0 + d.y;
            }

            var minGroupValue = AG.d3.utilities.calculateLayersMin(layers, groupMinMaxFunc);
            minGroupValue = minGroupValue < 0 ? minGroupValue : 0;

            return {
                numLayers: numLayers,
                numSamples: numSamples,
                minGroupValue: minGroupValue,
                maxGroupValue: AG.d3.utilities.calculateLayersMax(layers, groupMinMaxFunc),
                maxStackValue: AG.d3.utilities.calculateLayersMax(layers, stackMinMaxFunc),
                firstDataLayer: layers[0] ? layers[0].data : null,
                data: layers
            };
        }

        /*
         * Calculate the height of the bar graph (once) and set it on the svg and primary container
         * Used to recalculate the width of the bar graph (once) and set it on the svg and primary container
         */
        function calculateDimensions() {
            var maxValue = components.maxStackValue,
                minValue = 0;

            // allow negatives on 'simple' stacked bar charts
            if (type === 'simple') {
                minValue = components.minGroupValue;
            }

            if (layout === AG.d3.constants.BAR_LAYOUT.GROUPED) {
                maxValue = components.maxGroupValue;
                minValue = components.minGroupValue;
            }



            var max = _relativeMax || maxValue,
                min = _relativeMin || minValue;
            // Set the svg & the actual graph container width
            svg.attr("width", width);

            x = d3.scale.linear()
                .rangeRound([0, width])
                .domain([min, max]);
            // Scale specifically for the data bars
            xBars = d3.scale.linear()
                .rangeRound([0, width])
                .domain([min, max]);

            _stackedHeight = barProperties.barHeight;
            _groupedHeight = barProperties.barHeight;
            if (components.numLayers > 1) {
                _groupedHeight = Math.floor((_stackedHeight - (barProperties.barSpacing * (components.numLayers - 1))) / components.numLayers);
            }
            _groupedHeightShift = barProperties.barSpacing + _groupedHeight;
            // Modify the spacing to ensure it all fits in if grouped
            _stackedHeight = ((components.numLayers - 1) * barProperties.barSpacing) + (components.numLayers * _groupedHeight);
        }

        function redrawGroupedElements(animate, maxValue) {
            // Position the labels
            positionHeaderLabels(maxValue);
            if (labelProperties.alwaysShowStacked) {
                drawStackedLabels(maxValue);
            } else {
                svg.selectAll(".stacked-labels")
                    .attr("opacity", 0);
            }
            // Reposition the labels as a group
            var labels = svg.selectAll(".grouped-labels").selectAll("text");
            // Position the labels
            var posFn = positionFunctions(labels, labelProperties.dataLocation);
            labels
                .attr("x", function (d) {
                    return posFn(this.getBBox().width, d.y, maxValue);
                })
                .attr("y", function (d, i) {
                    return i * _spacer;
                });
            // Draw the background bars
            svg.selectAll(".background rect")
                .attr("width", x(maxValue));
            svg.selectAll(".background-top rect")
                .attr("width", x(maxValue) - 2);
            // Draw the data bars
            var selection =
                svg.classed("grouped", true)
                    .classed("stacked", false)
                    .selectAll(".layer")
                    .selectAll("rect");
            if (animate) {
                d3.transition()
                    .duration(350)
                    .each(function () {
                        selection
                            .transition().attr("height", _groupedHeight)
                            .transition().attr("y", fy)
                            .transition().attr("x", fx)
                            .transition().attr("width", fw)
                            .each("end", function () {
                                svg.selectAll(".grouped-labels")
                                    .transition()
                                    .attr("opacity", 1);
                            });
                    });
            } else {
                selection
                    .attr("height", _groupedHeight)
                    .attr("y", fy)
                    .attr("x", fx)
                    .attr("width", fw);
                svg.selectAll(".grouped-labels")
                    .attr("opacity", 1);
            }

            function fw(d) {
                return Math.abs(xBars(d.y) - xBars(0));
            }

            function fx(d) {
                return d.y < 0 ? xBars(d.y) : xBars(0);
            }

            function fy(d, i, j) {
                return (i * _spacer) + j * _groupedHeightShift;
            }
        }

        function drawStackedLabels(maxValue) {
            // Only render the last layer for stacked
            var labels = svg.selectAll(".stacked-labels").selectAll("text");
            // Position the labels
            var posFn = positionFunctions(labels, labelProperties.stackLocation);
            labels
                .attr("x", function (d) {
                    return posFn(this.getBBox().width, d.y0 + d.y, maxValue);
                })
                .attr("y", function (d, i) {
                    return i * _spacer;
                })
                .attr("dy", "0.4em");
        }

        function redrawStackedElements(animate, maxValue) {
            // Position the labels
            positionHeaderLabels(maxValue);
            svg.selectAll(".grouped-labels")
                .attr("opacity", 0);
            drawStackedLabels(maxValue);

            // Draw the background bars
            svg.selectAll(".background rect")
                .attr("width", x(maxValue));
            svg.selectAll(".background-top rect")
                .attr("width", x(maxValue) - 2);

            // Draw the data bars
            var selection = svg
                .classed("stacked", true)
                .classed("grouped", false)
                .selectAll(".layer")
                .selectAll("rect");


            if (animate) {
                d3.transition()
                    .duration(350)
                    .each(function () {
                        selection
                            .transition().attr("width", fw)
                            .transition().attr("x", fx)
                            .transition().attr("y", fy)
                            .transition().attr("height", _stackedHeight);
                    });
            } else {
                selection
                    .attr("width", fw)
                    .attr("x", fx)
                    .attr("y", fy)
                    .attr("height", _stackedHeight);
            }

            function fw(d) {
                // 'simple' type can accomodate negative values, so calculate absolute widths
                // other types can't, so replace negative values with 0
                if (type === 'simple') {
                    return Math.abs(xBars(d.y) - xBars(0));
                } else {
                    let val = xBars(d.y) - xBars(0);
                    return val > 0 ? val : 0;
                }
            }

            function fx(d) {
                // 'simple' type can accomodate negative values, so x pos will be relative to width of largest negative number
                // other types can't, so x pos always at start
                if (type === 'simple') {
                    return d.y < 0 ? xBars(d.y) : xBars(0);
                } else {
                    return xBars(d.y0);
                }
            }

            function fy(d, i) {
                return i * _spacer;
            }
        }

        function recalculateRightMargin(textSelection) {
            var maxWidth = Math.ceil(d3.max(textSelection[0].map(function (text) {
                return text.getComputedTextLength();
            })));
            // Right margin
            x.rangeRound([0, x.range()[1] - maxWidth - _rightLabelSpace]);
            xBars.rangeRound([0, xBars.range()[1] - maxWidth - _rightLabelSpace]);
            return maxWidth;
        }

        function positionFunctions(labels, labelLocation) {
            var maxLabelWidth, largestDataValue, tempDataValue, labelIndex;
            switch (labelLocation) {
                case AG.d3.constants.LABEL_LOCATION.INSIDE_RIGHT:
                    if (!labels.empty()) {
                        largestDataValue = 0;
                        labelIndex = -1;
                        labels[0].forEach(function (label, i) {
                            tempDataValue = d3.select(label).datum().y;
                            if (tempDataValue > largestDataValue) {
                                largestDataValue = tempDataValue;
                                labelIndex = i;
                            }
                        });
                        maxLabelWidth = Math.ceil(labels[0][labelIndex].getComputedTextLength());
                        // Shorten the length of the data bar
                        xBars.rangeRound([0, xBars.range()[1] - maxLabelWidth - 8]);
                    }
                    // Right of the data bar
                    return function (width, value, maxValue) {
                        return xBars(value > 0 ? value : 0) + 4;
                    };
                case AG.d3.constants.LABEL_LOCATION.OUTSIDE_RIGHT:
                    if (!labels.empty()) {
                        maxLabelWidth = recalculateRightMargin(labels);
                    }
                    return function (width, value, maxValue) {
                        return x(maxValue) + _rightLabelSpace + (maxLabelWidth - Math.ceil(width));
                    };
            }
        }

        function positionHeaderLabels(maxValue) {
            var xShift = 0,
                yShift = 0,
                dy = "0",
                labels = svg.selectAll(".global-labels text");

            if (labelProperties.headerLocation === AG.d3.constants.LABEL_LOCATION.OUTSIDE_RIGHT) {
                var height = Math.floor(labels.node().getBBox().height / 2);
                recalculateRightMargin(labels);
                yShift = Math.floor(_stackedHeight / 2) + height;
                xShift = x(maxValue) + _rightLabelSpace;
                dy = "-0.16em";
            }

            labels
                .attr("y", function (d, i) {
                    return (i * _spacer) + yShift;
                })
                .attr("x", xShift)
                .attr("dy", dy);
        }

        chart.transition = function () {
            chart.resize(true);
            return chart;
        };

        chart.resize = function (animate) {
            calculateDimensions();

            var maxValue = components.maxStackValue,
                redrawFunc = redrawStackedElements;

            if (layout === AG.d3.constants.BAR_LAYOUT.GROUPED) {
                maxValue = components.maxGroupValue;
                redrawFunc = redrawGroupedElements;
            }
            maxValue = _relativeMax || maxValue;

            // Height of the inner group container
            var topLabelHeight = Math.ceil(svg.select(".global-labels text").node().getBBox().height),
                globalLabelPadding = 7,
                groupedLabelSpacer = 2;
            // 7 px from the baseline. 4px from the bottom
            if (labelProperties.headerLocation === AG.d3.constants.LABEL_LOCATION.OUTSIDE_RIGHT) {
                topLabelHeight = 0;
                globalLabelPadding = 0;
                groupedLabelSpacer = 1;
            }

            _spacer = barProperties.labelPadding + topLabelHeight + globalLabelPadding + _stackedHeight;

            height = _spacer
                * components.numSamples
                - barProperties.labelPadding + 1; // There is no padding before the 1st header

            ////
            /// Reposition the different layers
            ////

            svg.attr("height", height);
            // Global labels
            svg.selectAll(".global-labels")
                .attr("transform", "translate(0," + topLabelHeight + ")");
            // Backgrounds
            svg.selectAll(".background")
                .attr("transform", "translate(0, " + (topLabelHeight + globalLabelPadding) + ")")
                .selectAll("rect")
                .attr("height", _stackedHeight)
                .attr("y", function (d, i) {
                    return i * _spacer;
                });
            svg.selectAll(".background-top")
                .attr("transform", "translate(0, " + (topLabelHeight + globalLabelPadding + 1) + ")")
                .selectAll("rect")
                .attr("height", _stackedHeight - 2)
                .attr("y", function (d, i) {
                    return i * _spacer;
                });
            // Grouped labels
            svg.selectAll(".grouped-labels")
                .attr("transform", function (d, i) {
                    return "translate(0," + (topLabelHeight + globalLabelPadding + Math.floor(_groupedHeightShift * i) + (_groupedHeight / 2) - groupedLabelSpacer) + ")";
                });
            // Stacked labels
            svg.selectAll(".stacked-labels")
                .attr("transform", "translate(0," + (topLabelHeight + globalLabelPadding + Math.floor(_stackedHeight / 2)) + ")");
            // Data bars
            svg.selectAll(".layer")
                .attr("transform", "translate(0, " + (topLabelHeight + globalLabelPadding) + ")");
            // Redraw the components
            redrawFunc(animate, maxValue);

            return chart;
        };

        chart.width = function (_) {
            if (!arguments.length) {
                return width;
            }
            width = _;
            return chart;
        };

        chart.height = function (_) {
            if (!arguments.length) {
                return height;
            }
            height = _;
            return chart;
        };

        chart.layout = function (_) {
            if (!arguments.length) {
                return layout;
            }
            layout = _;
            return chart;
        };

        chart.type = function (_) {
            if (!arguments.length) {
                return type;
            }
            type = _;
            return chart;
        };

        chart.barProperties = function (_) {
            if (!arguments.length) {
                return barProperties;
            }
            barProperties = _;
            return chart;
        };

        chart.labelProperties = function (_) {
            if (!arguments.length) {
                return labelProperties;
            }
            labelProperties = _;
            return chart;
        };

        chart.labelFormatters = function (_) {
            if (!arguments.length) {
                return labelFormatters;
            }
            labelFormatters = _;
            return chart;
        };

        chart.width(width || 600);

        chart.layout(layout || AG.d3.constants.BAR_LAYOUT.STACKED);

        chart.labelProperties(labelProperties || {
            headerLocation: AG.d3.constants.LABEL_LOCATION.ABOVE_BAR,
            stackLocation: AG.d3.constants.LABEL_LOCATION.OUTSIDE_RIGHT,
            dataLocation: AG.d3.constants.LABEL_LOCATION.OUTSIDE_RIGHT,
            domainLocation: AG.d3.constants.LABEL_LOCATION.OFF,
            alwaysShowStacked: false
        });

        chart.labelFormatters(labelFormatters || {
            dataFormatter: function (number) {
                return accounting.formatNumber(number, 1, " ") + "%";
            }
        });

        chart.barProperties(barProperties || {
            labelPadding: 25, // Adds to 30 because of the text baseline
            barSpacing: 4,
            barHeight: 20,
            removeIfEmpty: false
        });

        return chart;
    };

})(d3, accounting);
