var jQuery = require('jquery');

window.AG = window.AG || {};
AG.ui = AG.ui || {};

AG.ui.modals = (function ($) {
    "use strict";

    var measureScrollbar = function () {
        var scrollDiv = document.createElement('div');
        scrollDiv.className = 'modal-scrollbar-measure';
        $(document.body).append(scrollDiv);
        var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
        $(document.body)[0].removeChild(scrollDiv);
        return scrollbarWidth;
    };

    $(document).on("click", "[data-toggle='modal']", function () {
        var modal = $(".modal-content");
        if (window.matchMedia("(min-width: 768px)").matches) {
            modal.css("right", (measureScrollbar() / 2) + "px");
            // modal.height($(window).height() * 0.79);
            modal.css("margin-top", $(window).height() * 0.1);
            modal.css("margin-bottom", '0');
        } else {
            modal.height("auto");
        }
    });

    $(document).on("click", ".js-notice-close", function () {
        var $notice = $(this).closest(".notice"),
            $body = $("body");
        $notice.fadeOut(250, function () {
            $body.removeClass("no-scrolling");
        });
    });

}(jQuery));
